import { Breakpoint, Color, FontWeight, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContentWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  margin: 0 auto;
`;

export const ContentBox: StyledComponent<'div'> = styled.div`
  width: 100%;
  max-width: 838px;
  margin: 0 auto;
`;

export const CodeInfo: StyledComponent<'div'> = styled.div`
  display: flex;
  min-height: 228px;
  width: 100%;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
  }
`;

export const CodeInfoImageContainer: StyledComponent<'div'> = styled.div`
  position: relative;
  max-width: 431px;
  max-height: 100%;
  width: 50%;

  @media ${Breakpoint.Mobile} {
    width: 100%;
    max-width: unset;
  }
`;

export const CodeInfoImageWrapper: StyledComponent<'img'> = styled.img`
  width: 100%;
  object-fit: cover;
  height: 100%;
`;

export const CodeInfoRightSection: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  width: 100%;
  padding-left: 75px;
  background: linear-gradient(85deg, ${Color.ICBlack100} 0.65%, ${Color.ICBlack500} 110.02%); 

  @media ${Breakpoint.Tablet} {
    padding-left: 45px;
    padding-right: 20px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 20px 20px 30px;
  }
`;

export const CodeInfoSubHeader: StyledComponent<'div'> = styled.div`
  color: ${Color.ICWhite100};
  font-size: 14px;
  line-height: 16px;
`;

export const CodeInfoHeader: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.ExtraBold};
  font-size: 34px;
  line-height: 41px;
  color: ${Color.ICWhite100};
  padding-bottom: 26px;

  @media ${Breakpoint.Tablet} {
    padding-bottom: 10px;
  }
`;

export const CodeInfoText: StyledComponent<'div'> = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: ${Color.ICWhite100};
  white-space: nowrap;
  margin-right: 8px;
`;

export const CodeInfoRow: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  padding-right: 20px;
  flex-wrap: wrap;
`;

export const CodeDetailsBox: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 62px 0;
`;

export const UseDetails: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const UseDetailsBoxes: StyledComponent<'div'> = styled.div`
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 5px));
  grid-gap: 10px;
  padding-bottom: 62px;

  @media ${Breakpoint.SmallMobile} {
    grid-template-columns: 100%;
    width: 100%;
  }
`;

export const Transactions: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  border-bottom: 1px solid ${Color.ICGray500};
`;

export const TransactionsWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TransactionsItem: StyledComponent<'div'> = styled.div`
  padding: 24px 0;

  & + & {
    border-top: 1px solid ${Color.ICGray500};
  }
`;

export const AccordionSection: StyledComponent<'div'> = styled.div``;

export const AccordionWrapper: StyledComponent<'div'> = styled.div`
  margin: 40px 0 48px;

  @media ${Breakpoint.Mobile} {
    margin: 40px -20px 48px;
  }
`;

export const AccordionHeader: StyledComponent<'div'> = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 36%);
  grid-gap: 24px;
  border-bottom: 1px solid ${Color.ICGray500};
  padding: 17px 0;

  @media ${Breakpoint.Mobile} {
    display: none;
  }
`;

export const AccordionHeaderText: StyledComponent<'span'> = styled.span`
  font-size: 10px;
  line-height: 12px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.ICWhite100};
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const AccordionTransactions: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;

  @media ${Breakpoint.Mobile} {
    padding: 0 20px;
  }
`;

export const AccordionUseDetailsBoxes: StyledComponent<'div'> = styled.div`
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 5px));
  grid-gap: 10px;
  padding-bottom: 40px;

  @media ${Breakpoint.Mobile} {
    padding: 0 20px 40px;
  }
  
  @media ${Breakpoint.SmallMobile} {
    grid-template-columns: 100%;
    width: 100%;
  }
`;
