import { Breakpoint, Color, Grid, PageTitle, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Color.ICBlack500};
  gap: 48px;
  width: 100%;
  padding: 24px 24px 62px;

  @media ${Breakpoint.Mobile} {
    padding: 30px 20px 60px;
  }
`;

export const StyledPageTitle: StyledComponent<typeof PageTitle> = styled(PageTitle)`
  width: 100%;
  max-width: ${Grid.ICDesktop};
  margin: 0 auto;
`;

export const ContentBox: StyledComponent<'div'> = styled.div`
  width: 100%;
  max-width: ${Grid.ICDesktop};
  margin: 0 auto;
`;
