import { Breakpoint, Button, CalcSize, Color, Grid, Input, Pagination, StyledComponent } from '@chic-loyalty/ui';
import { FileFromViews } from '@chic/enums';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  min-height: calc(100vh - ${CalcSize.FooterHeight});
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('${FileFromViews.SubscriptionsListViewBackground}');
  background-color: ${Color.ICBlack500};
  background-repeat: no-repeat;
  background-size: 100% 240px;

  @media ${Breakpoint.Mobile} {
    background-size: contain;
  }
`;

export const PageTitleWrapper: StyledComponent<'div'> = styled.div`
  max-width: calc(${Grid.ICSmallDesktop} + 48px);
  width: 100%;
  padding: 24px 24px 0;
`;

export const SearchContainer: StyledComponent<'div'> = styled.div`
  max-width: calc(${Grid.ICSmallDesktop} + 48px);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 48px 24px 62px;
`; 

export const SearchBar: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  gap: 36px;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    gap: 12px;
  }
`;

export const StyledInput: StyledComponent<typeof Input> = styled(Input)`
  width: 100%;
`;

export const FilterButton: StyledComponent<typeof Button> = styled(Button)`
  height: 47px;
  width: 132px;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const MainContainer: StyledComponent<'div'> = styled.div`
  max-width: calc(${Grid.ICSmallDesktop} + 48px);
  width: 100%;
  height: 100%;
  padding: 0 24px 62px;
`;

export const StyledPagination: StyledComponent<typeof Pagination> = styled(Pagination)`
  margin: 48px 0;
`;

export const TableButton: StyledComponent<typeof Button> = styled(Button)`
  max-width: 204px;
  width: 100%;
`;

export const TableWrapper: StyledComponent<'div'> = styled.div``;
