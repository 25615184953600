import React, { Dispatch, useEffect, useReducer, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { 
  Container, 
  FilterButton, 
  MainContainer, 
  PageTitleWrapper, 
  SearchBar, 
  SearchContainer, 
  StyledInput, 
  StyledPagination,
  TableButton,
  TableWrapper, 
} from './subscriptionsList.styled';
import { 
  ButtonTheme, 
  Color, 
  ComponentColorTheme, 
  DetailsTable, 
  DetailsRowTheme, 
  DetailsTableCellTheme, 
  DetailsTableCellType, 
  DetailsTableRow,
  IconName, 
  PageTitle, 
  PageTitleSize, 
  PaginationDetails, 
  ResultInfoBox, 
  TextAlign, 
  useNotifications, 
  UseNotifications, 
  usePagination, 
  useParsers, 
  UseParsers, 
  useRedirect, 
  UseRedirect, 
  UseState, 
  ValidationBar, 
  ValidationBarTheme, 
  VisibilitySettings,
  IconPosition, 
} from '@chic-loyalty/ui';
import { useTitle } from '@chic/hooks';
import { getSubscriptions } from '@chic/api';
import { FileFromViews, QueryKey, RoutingPath, SortOrder, SubscriptionSortKey } from '@chic/enums';
import { SubscriptionsListAction, SubscriptionsListState } from './subscriptionsList.types';
import { subscriptionsListReducer } from './subscriptionsList.reducer';
import { subscriptionsListInitialState, subscriptionStatus } from './subscriptionsList.const';
import { SubscriptionListElement, ListPagination, FrontendApiError } from '@chic/models';
import { useQuery } from 'react-query';
import { SubscriptionsListActions, SubscriptionsListColumnName, SubscriptionsListViewState } from './subscriptionsList.enum';
import { UseSearchParams } from '@chic/types';
import { useSearchParams } from 'react-router-dom';

export const SubscriptionsListView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { setMaxItems, pagesCount, page, setPage, itemsPerPage, offset }: PaginationDetails = usePagination();
  const { addToast }: UseNotifications = useNotifications();
  const { parsePrice }: UseParsers = useParsers();
  const { redirect }: UseRedirect = useRedirect();
  const [searchParams, setSearchParams]: UseSearchParams = useSearchParams();
  const [inputQuery, setInputQuery]: UseState<string> = useState<string>('');
  const [viewState, setViewState]: UseState<SubscriptionsListViewState> 
    = useState<SubscriptionsListViewState>(SubscriptionsListViewState.StartView);
  const [subscriptionListTable, setSubscriptionsListTable]: UseState<DetailsTableRow[] | null> 
    = useState<DetailsTableRow[] | null>(null);
  const [state, dispatch]: [SubscriptionsListState, Dispatch<SubscriptionsListAction>] 
    = useReducer(subscriptionsListReducer, subscriptionsListInitialState);
  useTitle(t('chic.management.subscriptionsListView.title'));
  const currency: string = 'zł';

  const redirectToSubscriptionDetails: (id: number) => void = (id: number): void => {
    redirect(RoutingPath.SubscriptionDetails, { subscriptionId: id });
  };

  useQuery(
    [QueryKey.SubscriptionsList, state, page, itemsPerPage, offset],
    (): Promise<ListPagination<SubscriptionListElement>> => getSubscriptions({
      query: state.filterValue,
      sort: state.sortKey,
      order: state.sortOrder,
      limit: itemsPerPage,
      offset,
    }),
    {
      enabled: !!state.filterValue.length,
      onSuccess: (data: ListPagination<SubscriptionListElement>): void => {
        if (!data.amount) {
          setViewState(SubscriptionsListViewState.EmptyListView);
          return;
        }
        setViewState(SubscriptionsListViewState.FullfieldView);
        setMaxItems(data.amount);
        setSubscriptionsListTable([
          ...data.list.map((element: SubscriptionListElement): DetailsTableRow => ({
            theme: DetailsRowTheme.Normal,
            cells: [
              {
                name: SubscriptionsListColumnName.ContractNumber,
                value: element.contractNumber,
                cellTheme: DetailsTableCellTheme.ICWhite,
                cellType: DetailsTableCellType.Text,
                visibilitySettings: VisibilitySettings.Always,
                iconDetails: {
                  icon: IconName.FileOther,
                  iconColor: Color.ICYellow100,
                  iconPosition: IconPosition.Left,
                },
              },
              {
                name: SubscriptionsListColumnName.PlanName,
                value: element.planName,
                cellTheme: DetailsTableCellTheme.ICWhite,
                cellType: DetailsTableCellType.Text,
                visibilitySettings: VisibilitySettings.Always,
              },
              {
                name: SubscriptionsListColumnName.Price,
                value: `${parsePrice(element.currentValue)} ${currency}`,
                cellTheme: DetailsTableCellTheme.ICYellow,
                cellType: DetailsTableCellType.Text,
                visibilitySettings: VisibilitySettings.Always,
              },
              {
                name: SubscriptionsListColumnName.Status,
                value: t(subscriptionStatus[element.status].label),
                cellTheme: subscriptionStatus[element.status].theme,
                cellType: DetailsTableCellType.Text,
                visibilitySettings: VisibilitySettings.Always,
              },
              {
                name: SubscriptionsListColumnName.SubscriptionDetails,
                value: '',
                cellTheme: DetailsTableCellTheme.ICYellow,
                cellType: DetailsTableCellType.Children,
                visibilitySettings: VisibilitySettings.Always,
                textAlign: TextAlign.Right,
                customAttributes: {
                  children: (
                    <TableButton 
                      label={t('chic.management.subscriptionsListView.redirectToSubscriptionDetails.label')} 
                      buttonTheme={ButtonTheme.ICPrimary} 
                      onClick={(): void => redirectToSubscriptionDetails(element.id)}
                    />
                  ),
                },
              },
            ],
          })),
        ]);
      },
      onError: (error: FrontendApiError): void => {
        setViewState(SubscriptionsListViewState.EmptyListView);
        addToast({ content: error.message ?? '' });
      },
    },
  );

  const handleFilterValueAction: (searchParam?: string) => void = (searchParam?: string): void => {
    if (searchParam || inputQuery.length) {
      dispatch({ type: SubscriptionsListActions.SetFilterValue, payload: searchParam ?? inputQuery });
      setSearchParams({ search: searchParam ?? inputQuery });
    }
  };

  const handleSortAction: (sortKey: SubscriptionSortKey) => void = (
    sortKey: SubscriptionSortKey,
  ): void => {
    dispatch({ type: SubscriptionsListActions.SetSortKey, payload: sortKey });
    dispatch({
      type: SubscriptionsListActions.SetSortOrder,
      payload: state.sortOrder === SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending,
    });
  };

  const handleEnterClick: (event: React.KeyboardEvent<HTMLInputElement>) => void = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (event.key === 'Enter' && inputQuery.length) {
      handleFilterValueAction();
    }
  };

  useEffect(
    (): void => {
      const searchParam: string | null = searchParams.get('search');
      if (searchParam?.length) {
        setInputQuery(searchParam);
        setViewState(SubscriptionsListViewState.FullfieldView);
        if (!state.filterValue) {
          handleFilterValueAction(searchParam);
        }
      }
    },
    [searchParams],
  );

  return (
    <Container>
      <PageTitleWrapper>
        <PageTitle size={PageTitleSize.Big} label={t('chic.management.subscriptionsListView.pageTitle')} />
      </PageTitleWrapper>
      <SearchContainer>
        <SearchBar>
          <StyledInput 
            colorTheme={ComponentColorTheme.IC} 
            placeholder={t('chic.management.subscriptionsListView.searchBar.placeholder')} 
            onChange={setInputQuery}
            onKeyDown={handleEnterClick}
            value={inputQuery}
          />
          <FilterButton 
            label={t('chic.management.subscriptionsListView.searchBar.filterButton')} 
            icon={IconName.Filtering} 
            onClick={(): void => handleFilterValueAction()} 
            buttonTheme={ButtonTheme.ICPrimary} 
            disabled={!inputQuery.length}
          />
        </SearchBar>
        <ValidationBar 
          barTheme={ValidationBarTheme.GrayGradient} 
          colorTheme={ComponentColorTheme.IC}
          icon={IconName.SmallInfo}
          message={t('chic.management.subscriptionsListView.search.message')}
        />
      </SearchContainer>
      <MainContainer>
        {viewState === SubscriptionsListViewState.StartView && (
          <ResultInfoBox 
            message={t('chic.management.subscriptionsListView.startView.message')}
            iconImg={FileFromViews.SubscriptionsListViewSearchIcon}
            backgroundImage={FileFromViews.SubscriptionsListViewSearchBackground}
            colorTheme={ComponentColorTheme.IC}
          />
        )}
        {viewState === SubscriptionsListViewState.EmptyListView && (
          <ResultInfoBox 
            header={t('chic.management.subscriptionsListView.emptyListView.header')}
            message={t('chic.management.subscriptionsListView.emptyListView.message')}
            iconImg={FileFromViews.SubscriptionsListViewSearchIcon}
            colorTheme={ComponentColorTheme.IC}
          />
        )}
        {viewState === SubscriptionsListViewState.FullfieldView && !!subscriptionListTable && (
          <TableWrapper>
            <DetailsTable 
              headers={[{
                name: SubscriptionsListColumnName.ContractNumber,
                label: t('chic.management.subscriptionsListView.subscriptionListTable.contractNumber.header'),
                onSortClick: (): void => handleSortAction(SubscriptionSortKey.ContractNumber),
              }, {
                name: SubscriptionsListColumnName.PlanName,
                label: t('chic.management.subscriptionsListView.subscriptionListTable.planName.header'),
                onSortClick: (): void => handleSortAction(SubscriptionSortKey.PlanId),
              }, {
                name: SubscriptionsListColumnName.Price,
                label: t('chic.management.subscriptionsListView.subscriptionListTable.price.header'),
              }, {
                name: SubscriptionsListColumnName.Status,
                label: t('chic.management.subscriptionsListView.subscriptionListTable.status.header'),
                onSortClick: (): void => handleSortAction(SubscriptionSortKey.Status),
              }]}
              tableData={subscriptionListTable}
              columnsVisibleOnMobile={[SubscriptionsListColumnName.ContractNumber, SubscriptionsListColumnName.PlanName]}
              colorTheme={ComponentColorTheme.IC}
            />
            <StyledPagination 
              activePage={page} 
              pagesCount={pagesCount} 
              onActivePageChange={setPage} 
              colorTheme={ComponentColorTheme.IC} 
            />
          </TableWrapper>
        )}
      </MainContainer>
    </Container>
  );
};
