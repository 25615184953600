import { AnimationContext } from '@chic/contexts';
import { AnimationAction } from '@chic/enums';
import { AnimationContextType, AnimationState, UseAnimationsContext } from '@chic/models';
import { useContext } from 'react';

export const useAnimationsContext: () => UseAnimationsContext = (): UseAnimationsContext => {
  const [animationData, dispatch]: AnimationContextType = useContext(AnimationContext);

  const saveAnimationStateValues: (data: AnimationState) => void = (data: AnimationState): void => {
    dispatch({ type: AnimationAction.SaveAnimationStateValues, payload: data });
  };

  const clearSessionStorage: () => void = (): void => {
    dispatch({ type: AnimationAction.ClearSessionStorage });
  };

  return { animationData, saveAnimationStateValues, clearSessionStorage };
};
