import React, { Dispatch, useEffect, useMemo, useReducer } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import { GlobalStyle } from '../theme/globalStyle.theme';
import {
  DashboardView,
  DiscountCodeDetailsView,
  DiscountCodesSearchView,
  NotFoundView,
  CrmSignInView,
  CrmSignInRedirectView,
  ChangePasswordView,
  SignInView,
  CrmSignInAfterRedirectView,
  ProfileView,
  RemindPasswordView,
  UserRoleVerificationListView,
  UserRoleVerificationView,
  SubscriptionsListView,
  SubscriptionDetailsView,
  AdsListView,
  AdAddView,
  AdEditView,
  AdAddScreenView,
  AdEditScreenView,
  // TODO: uncomment when be for refunds will be ready
  // RefundsListView,
} from '../views';
import { AppWrapper, CheckAuth } from '@chic/components';
import { LocalStorageKey, RoutingPath, SessionStorage } from '@chic/enums';
import { AnimationActions, AnimationContextType, AnimationState, AppState, AuthContextType, AuthState, UseInitial } from '@chic/models';
import { useInitial } from '@chic/hooks';
import { animationReducer, appStateReducer, authReducer } from '@chic/reducers';
import { AuthContext, ConfigsContext, AppStateContext, AnimationContext } from '@chic/contexts';
import { initialAppState } from '@chic/constans';
import { ComponentColorTheme, FullscreenAlertSettings, NotificationsProvider, UseLocalStorage, useLocalStorage } from '@chic-loyalty/ui';

const queryClient: QueryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } } });

const App: React.FC = (): JSX.Element => {
  const [loggedUserData]: UseLocalStorage<string | null> = useLocalStorage<string | null>(LocalStorageKey.LoggedUserData, '');
  const [serializedAppState, setAppStateStorage]: UseLocalStorage<string | null> = useLocalStorage<string | null>(
    LocalStorageKey.AppState, '',
  );
  const [authState, authDispatch] = useReducer(authReducer, JSON.parse(loggedUserData || '{}') as AuthState);
  const appStateFromLocalStorage: AppState | null = serializedAppState ? (serializedAppState || '{}') as unknown as AppState : null;
  const [appState, appStateDispatch] = useReducer(
    appStateReducer,
    {
      ...initialAppState,
      ...(appStateFromLocalStorage ?? {}),
    },
  );
  const animationData: string | null = sessionStorage.getItem(SessionStorage.AnimationData);
  const [animationState, animationDispatch]: [AnimationState, Dispatch<AnimationActions>] 
    = useReducer(animationReducer, JSON.parse(animationData || '{}') as AnimationState);
  const valueForAnimationContext: AnimationContextType = useMemo(
    (): AnimationContextType => [animationState, animationDispatch],
    [animationState],
  );
  const valueForAuthContext: AuthContextType = useMemo((): AuthContextType => [authState, authDispatch], [authState]);
  const { configs }: UseInitial = useInitial();

  // TODO: Fix NotificationsProvider to take initial state and fix serialization for functions
  const fullscreenAlertData: FullscreenAlertSettings | undefined = useMemo(
    (): FullscreenAlertSettings | undefined => {
      if (appState.fullscreenAlertData) {
        return appState.fullscreenAlertData;
      }
    },
    [appStateFromLocalStorage],
  );

  useEffect(
    (): void => {
      setAppStateStorage(JSON.stringify(appState));
    },
    [appState],
  );

  useEffect(
    (): void => {
      sessionStorage.setItem(SessionStorage.AnimationData, JSON.stringify(animationState));
    },
    [animationState],
  );

  // TODO: add loader
  return configs ? (
    <NotificationsProvider colorTheme={ComponentColorTheme.IC}>
      <ConfigsContext.Provider value={configs}>
        <AuthContext.Provider value={valueForAuthContext}>
          <AnimationContext.Provider value={valueForAnimationContext}>
            <QueryClientProvider client={queryClient}>
              <AppStateContext.Provider value={[appState, appStateDispatch]}>
                <Router>
                  <GlobalStyle />
                  <AppWrapper fullscreenAlertData={fullscreenAlertData}>
                    <Routes>
                      {/* common */}
                      <Route path={RoutingPath.SignIn} element={
                        <CheckAuth><SignInView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.RemindPassword} element={
                        <CheckAuth><RemindPasswordView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.ChangePassword} element={
                        <CheckAuth><ChangePasswordView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.CrmSignIn} element={
                        <CheckAuth><CrmSignInView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.CrmSignInRedirect} element={
                        <CheckAuth><CrmSignInRedirectView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.ReturnFromCrm} element={
                        <CheckAuth><CrmSignInAfterRedirectView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.Dashboard} element={
                        <CheckAuth><DashboardView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.Profile} element={
                        <CheckAuth><ProfileView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.UserRoleVerificationList} element={
                        <CheckAuth><UserRoleVerificationListView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.UserRoleVerification} element={
                        <CheckAuth><UserRoleVerificationView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.DiscountCodesSearch} element={
                        <CheckAuth><DiscountCodesSearchView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.DiscountCodeDetails} element={
                        <CheckAuth><DiscountCodeDetailsView /></CheckAuth>
                      } />
                      {/* custom for forks apps */}
                      <Route path={RoutingPath.AdAdd} element={
                        <CheckAuth><AdAddView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.AdAddScreen} element={
                        <CheckAuth><AdAddScreenView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.AdEdit} element={
                        <CheckAuth><AdEditView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.AdEditScreen} element={
                        <CheckAuth><AdEditScreenView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.AdsList} element={
                        <CheckAuth><AdsListView /></CheckAuth>
                      } />
                      {/* // TODO: uncomment when be for refunds will be ready */}
                      {/* <Route path={RoutingPath.RefundsList} element={
                        <CheckAuth><RefundsListView /></CheckAuth>
                      } /> */}
                      <Route path={RoutingPath.SubscriptionList} element={
                        <CheckAuth><SubscriptionsListView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.SubscriptionDetails} element={
                        <CheckAuth><SubscriptionDetailsView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.NotFound} element={
                        <CheckAuth><NotFoundView /></CheckAuth>
                      } />
                    </Routes>
                  </AppWrapper>
                </Router>
              </AppStateContext.Provider>
            </QueryClientProvider>
          </AnimationContext.Provider>
        </AuthContext.Provider>
      </ConfigsContext.Provider>
    </NotificationsProvider>
  ) : <></>;
};

export default App;
