import React, { useState } from 'react';

import { Container, Form, StyledButton, ButtonWrapper, StyledLink, StyledValidationBar } from './signInForm.styled';
import { classicLogin } from '@chic/api';
import { FrontendApiError, LoginData, LoginErrorData, SimpleAuthData } from '@chic/models';
import { useAuth } from '@chic/hooks';
import { Formik, FormikProps, FormikValues } from 'formik';
import { stringify } from 'query-string';
import { ButtonTheme, ComponentColorTheme, Input, InputTheme, UseRedirect, ValidationBarTheme, useRedirect } from '@chic-loyalty/ui';
import { RoutingPath, UserLoginErrorType } from '@chic/enums';
import { TransProps, useTranslation } from 'react-i18next';

export const SignInForm: React.FC = (): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { signIn } = useAuth();
  const { redirect }: UseRedirect = useRedirect();
  const { t }: TransProps<never> = useTranslation();

  const loginMethod = (values: SimpleAuthData): void => {
    setIsLoading(true);
    classicLogin(values)
      .then((data: LoginData) => {
        setIsLoading(false);
        signIn({ user: data.user, token: data.token });
      })
      .catch((error: FrontendApiError<LoginErrorData | {}>): void => {
        if ((error.data as LoginErrorData).type === UserLoginErrorType.ChangePasswordRequired) {
          redirect(
            `${RoutingPath.ChangePassword}?${stringify({ t: (error.data as LoginErrorData).token.value }, { skipEmptyString: true })}`,
          );
        }
        setErrorMessage(error.message);
        setIsLoading(false);
      });
  };

  return (
    <Container>
      <Formik initialValues={{ login: '', password: '' }} onSubmit={loginMethod}>
        {({ handleSubmit, setFieldValue, values }: FormikProps<FormikValues>) => (
          <Form onSubmit={handleSubmit}>
            <Input
              onChange={(value: string): void => setFieldValue('login', value)}
              label={t('chic.crmApp.global.login.label')}
              placeholder={t('chic.crmApp.global.login.placeholder')}
              inputTheme={errorMessage ? InputTheme.Error : InputTheme.Standard}
              colorTheme={ComponentColorTheme.IC}
              required
            />
            <Input
              onChange={(value: string): void => setFieldValue('password', value)}
              label={t('chic.crmApp.global.password.label')}
              placeholder={t('chic.crmApp.global.password.placeholder')}
              type='password'
              inputTheme={errorMessage ? InputTheme.Error : InputTheme.Standard}
              colorTheme={ComponentColorTheme.IC}
              required
            />
            <StyledLink to={RoutingPath.RemindPassword}>{t('chic.crmApp.loginFormSection.remindPassword')}</StyledLink>
            <ButtonWrapper>
              <StyledButton
                label={t('chic.crmApp.global.login.buttonLabel')}
                type='submit'
                isLoading={isLoading}
                disabled={!values.login || !values.password}
                buttonTheme={ButtonTheme.ICPrimary}
              />
            </ButtonWrapper>
          </Form>
        )}
      
      </Formik>
      {errorMessage && (
        <StyledValidationBar barTheme={ValidationBarTheme.Red} message={errorMessage} colorTheme={ComponentColorTheme.IC} />
      )}
    </Container>  
  );
};
