import { 
  Breakpoint, 
  Button, 
  CalcSize, 
  Color, 
  FontWeight, 
  Grid, 
  Input, 
  Pagination, 
  ResultInfoBox, 
  Status, 
  StyledComponent, 
} from '@chic-loyalty/ui';
import styled, { css } from 'styled-components';
import { DotProps } from './discountCodesSearch.interface';

const TableMobileWrapper = css`
  flex-wrap: wrap;
  gap: 2px;

  @media ${Breakpoint.Mobile} {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - ${CalcSize.HealthWarningHeight} - ${CalcSize.FooterHeight});
  background-color: ${Color.ICBlack500};
  padding: 24px 24px 62px;

  @media ${Breakpoint.Mobile} {
    padding: 30px 20px 60px;
  }
`;

export const ContentWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${Grid.ICDesktop};
  margin: 0 auto;
`;

export const ContentBox: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 838px;
  padding: 62px 0;
  margin: 0 auto;
`;

export const SearchWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  
  @media ${Breakpoint.Mobile} {
    gap: 16px;
  }
`;

export const StyledResultInfoBox: StyledComponent<typeof ResultInfoBox> = styled(ResultInfoBox)`
  font-weight: ${FontWeight.Regular};
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  width: auto;
`;

export const StyledInput: StyledComponent<typeof Input> = styled(Input)`
  width: 100%;
`;

export const Table: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 838px;
  margin: 0 auto;
`;

export const TableItemsWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px 0;
`;

export const CodesAmountText: StyledComponent<'div'> = styled.div`
  font-size: 22px;
  line-height: 24px;
  font-weight: ${FontWeight.ExtraBold};
  color: ${Color.ICWhite100};
  padding-left: 28px;
  margin: 25px 0 15px;
  position: relative;

  &::before {
    content: '';
    height: 1px;
    width: 16px;
    background-color: ${Color.ICWhite100};
    position: absolute;
    top: 50%;
    left: 0;
    translate: translateY(-50%);
  }
`;

export const CodesAmount: StyledComponent<'span'> = styled.span`
  font-weight: ${FontWeight.Regular};
  color: ${Color.ICYellow100};
`;

export const TableCode: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: ${FontWeight.Bold};
`;

export const TableExpirationWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  ${TableMobileWrapper}
`;

export const TablePromoCodeWrapper: StyledComponent<typeof TableExpirationWrapper> = styled(TableExpirationWrapper)`
  display: none;

  @media ${Breakpoint.Mobile} {
    display: flex;
  }
`;

export const TableCodeMobile: StyledComponent<'span'> = styled.span`
  display: none;
  font-weight: ${FontWeight.Bold};

  @media ${Breakpoint.Mobile} {
    display: inline-block;
  }
`;

export const TableMobileDescription: StyledComponent<'span'> = styled.span`
  display: none;
  color: ${Color.ICWhite100};
  white-space: nowrap;

  @media ${Breakpoint.Mobile} {
    display: inline-block;
  }
`;

export const StyledStatus: StyledComponent<typeof Status> = styled(Status)`
  white-space: nowrap;
  
  @media ${Breakpoint.Mobile} {
    display: none;
  }
`;

export const MobileCodesList: StyledComponent<'div'> = styled.div`
  margin: 40px -20px 0;
`;

export const MobileCodesItem: StyledComponent<'div'> = styled.div`
  color: ${Color.ICWhite100};
  border-top: 1px solid ${Color.ICGray500};
  padding-top: 10px;

  & + & {
    border-bottom: 1px solid ${Color.ICGray500};
  }
`;

export const MobileCodesRow: StyledComponent<'span'> = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  padding: 12px 20px;
`;

export const MobileCodesCode: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const MobileCodesLabel: StyledComponent<'div'> = styled.div`
  font-size: 10px;
  line-height: 12px;
  font-weight: ${FontWeight.Bold};
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const MobileCodesValue: StyledComponent<'div'> = styled.div`
  font-size: 14px;
  line-height: 17px;
  text-align: right;
`;

export const MobileCodesButtonWrapper: StyledComponent<'div'> = styled.div`
  padding: 10px 20px 20px 32px;
`;

export const Dot: StyledComponent<'div', DotProps> = styled.div<DotProps>`
  display: none;
  height: 10px;
  width: 10px;
  background-color: ${( props: DotProps ) => props.$background};
  border-radius: 50%;
  margin-left: auto;

  @media ${Breakpoint.Mobile} {
    display: block;
  }
`;

export const StyledPagination: StyledComponent<typeof Pagination> = styled(Pagination)`
  margin-top: 48px;
`;
