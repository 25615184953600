import { Breakpoint, CalcSize, Color, Grid, hexToRgba, StyledComponent } from '@chic-loyalty/ui';
import { FileFromViews } from '@chic/enums';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { ContainerProps } from './baseViewForAnimations.types';

export const Container: StyledComponent<'div', ContainerProps> = styled.div<ContainerProps>`
  width: 100%;
  min-height: calc(100vh - ${CalcSize.FooterHeight});
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Color.ICBlack500};
  ${({ $withBackgroundImage }: ContainerProps): FlattenSimpleInterpolation => $withBackgroundImage ? css`
    background: url('${FileFromViews.BaseViewForAnimationsBackground}');
    background-size: 100% 626px;
    background-repeat: no-repeat;
  ` : css``};

  @media ${Breakpoint.Mobile} {
    background-size: 100% 257px;
  }
`;

export const MobileHeaderButton: StyledComponent<'div'> = styled.div`
  padding: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${hexToRgba(Color.ICWhite100, 0.4)};
`;

export const PageTitleWrapper: StyledComponent<'div'> = styled.div`
  max-width: calc(${Grid.ICSmallDesktop} + 48px);
  width: 100%;
  padding: 24px 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InnerContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  flex: 1;
  padding: 62px 24px;
  max-width: calc(${Grid.ICSmallDesktop} + 48px);
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media ${Breakpoint.Mobile} {
    padding: 40px 0 0;
    gap: 0;
  }
`;

export const ButtonsWrapper: StyledComponent<'div'> = styled.div`
  padding: 40px 32px;
  background: linear-gradient(85.01deg, ${Color.ICBlack100} 0.65%, ${Color.ICBlack500} 110.02%);
  display: flex;
  justify-content: center;
  gap: 36px;
  width: 100%;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 28px 32px;
  }
`;

export const ButtonBannersBox: StyledComponent<'div'> = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    gap: 12px;
  }
`;

export const InputWrapper: StyledComponent<'div'> = styled.div`
  @media ${Breakpoint.Mobile} {
    padding: 0 20px 40px;
  }
`;

export const FileSettingsBoxWrapper: StyledComponent<'div'> = styled.div`
  margin: 64px 0;
  max-width: 100%;

  @media ${Breakpoint.Mobile} {
    padding: 40px 20px;
    margin: 0;
  }
`;

export const ButtonBannersWrapper: StyledComponent<'div'> = styled.div`
  padding: 40px 0;
  border-top: 1px solid ${Color.ICGray500};
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media ${Breakpoint.Mobile} {
    padding: 40px 20px;
  }
`;

export const ItemPickerTitle: StyledComponent<'div'> = styled.div`
  @media ${Breakpoint.Mobile} {
    padding: 0 20px;
  }
`;

export const ItemPickerWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media ${Breakpoint.Mobile} {
    padding: 40px 0;
    background-color: ${Color.ICBlack400};
  }
`;
