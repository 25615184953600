export enum SubscriptionsListActions {
  SetSortKey = 'setSortKey',
  SetSortOrder = 'setSortOrder',
  SetFilterValue = 'setFilterValue',
}

export enum SubscriptionsListColumnName {
  ContractNumber = 'contractNumber',
  PlanName = 'planName',
  Price = 'price',
  Status = 'status',
  SubscriptionDetails = 'subscriptionDetails',
}

export enum SubscriptionsListViewState {
  EmptyListView = 'emptyListView',
  FullfieldView = 'fullfieldView',
  StartView = 'startView',
}
