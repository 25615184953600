export enum DiscountCodesSearchTab {
  Code = 'code',
  Mail = 'mail',
  Phone = 'phone',
}

export enum DiscountCodeCellsNames {
  Code = 'code',
  ExpirationDate = 'expiration_date',
  PromotionName = 'promotion_name',
  Status = 'status',
  Link = 'link',
}

export enum DiscountCodesOrder {
  Asc = 'asc',
  Desc = 'desc',
}
