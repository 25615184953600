import { AxiosResponse } from 'axios';

import { 
  ApiReject, 
  ApiResolve, 
  KeyValue, 
  VerificationListParams, 
  BaseApiData, 
  ManagementUser, 
  Role, 
  VerificationList,
} from '@chic/models';
import { api } from '../api.service';
import { keyValuesToQueryParams } from '@chic/utils';

export const getUsersToVerification: (params?: VerificationListParams) => Promise<VerificationList> = (
  params?: VerificationListParams,
): Promise<VerificationList> => {
  return new Promise(
    (resolve: ApiResolve<VerificationList>, reject: ApiReject): Promise<void> => (
      api.get(`/management/verification${params ? `?${keyValuesToQueryParams(params as KeyValue)}` : ''}`)
        .then((response: AxiosResponse<VerificationList>): void => resolve(response?.data))
        .catch(reject)
    ),
  );
};

export const getUserManagementData: (userId: number) => Promise<ManagementUser> = (userId: number): Promise<ManagementUser> => new Promise(
  (resolve: ApiResolve<ManagementUser>, reject: ApiReject): Promise<void> => (
    api.get(`/management/user/${userId}/crm`)
      .then((response: AxiosResponse<ManagementUser>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const getUserRoles: () => Promise<Role[]> = (): Promise<Role[]> => new Promise(
  (resolve: ApiResolve<Role[]>, reject: ApiReject): Promise<void> => (
    api.get('/management/role')
      .then((response: AxiosResponse<Role[]>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const saveUserManagementRole: (userId: number, role: number) => Promise<BaseApiData> = (
  userId: number, role: number,
): Promise<BaseApiData> => new Promise(
  (resolve: ApiResolve<BaseApiData>, reject: ApiReject): Promise<void> => (
    api.post(`/management/verification/${userId}/verify`, { role })
      .then((response: AxiosResponse): void => resolve(response?.data))
      .catch(reject)
  ),
);
