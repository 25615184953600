export enum SubscriptionPaymentType {
  Przelewy24Pending = 'przelewy24_pending',
  PayuPending = 'payu_pending',
  Przelewy24 = 'przelewy24',
  Payu = 'payu',
  CardPayment = 'card_payment',
  Card = 'card',
  ManuallyAccepted = 'manually_accepted',
  PosPayment = 'pos_payment',
}
