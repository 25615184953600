import { AxiosResponse } from 'axios';

import { 
  ApiReject, 
  ApiResolve,
  ListPagination,
  DiscountCode,
  DiscountCodeDetails,
  CodeParams, 
  PaginationParams,
  DiscountCodeTransaction,
} from '@chic/models';
import { api } from '../../api.service';
import { stringify } from 'query-string';

export const getCodes: (params: CodeParams) => Promise<ListPagination<DiscountCode>> = (
  params: CodeParams,
): Promise<ListPagination<DiscountCode>> => new Promise(
  (resolve: ApiResolve<ListPagination<DiscountCode>>, reject: ApiReject): Promise<void> => (
    api.get(`/management/codes?${stringify(params, { skipEmptyString: true })}`)
      .then((response: AxiosResponse<ListPagination<DiscountCode>>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const getCodeDetails: (code: string) => Promise<DiscountCodeDetails> = (
  code: string,
): Promise<DiscountCodeDetails> => new Promise(
  (resolve: ApiResolve<DiscountCodeDetails>, reject: ApiReject): Promise<void> => (
    api.get(`/management/codes/${code}/details`)
      .then((response: AxiosResponse<DiscountCodeDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const getCodeTransactions: (code: string, params: PaginationParams) => Promise<ListPagination<DiscountCodeTransaction>> = (
  code: string, 
  params: PaginationParams,
): Promise<ListPagination<DiscountCodeTransaction>> => new Promise(
  (resolve: ApiResolve<ListPagination<DiscountCodeTransaction>>, reject: ApiReject): Promise<void> => (
    api.get(`/management/codes/${code}/transactions?${stringify(params, { skipEmptyString: true })}`)
      .then((response: AxiosResponse<ListPagination<DiscountCodeTransaction>>): void => resolve(response?.data))
      .catch(reject)
  ),
);
