import { Color } from '@chic-loyalty/ui';
import { SubscriptionDeliveryStatus, SubscriptionPaymentStatus } from '@chic/enums';

export const paymentStatusColor: Record<SubscriptionPaymentStatus, Color> = {
  [SubscriptionPaymentStatus.Success]: Color.ICGreen,
  [SubscriptionPaymentStatus.Failed]: Color.ICRed,
  [SubscriptionPaymentStatus.Rejected]: Color.ICRed,
  [SubscriptionPaymentStatus.Pending]: Color.ICBlue,
  [SubscriptionPaymentStatus.Refunded]: Color.ICYellow100,
};

export const deliveryStatusColor: Record<SubscriptionDeliveryStatus, Color> = {
  [SubscriptionDeliveryStatus.Collected]: Color.ICBlue,
  [SubscriptionDeliveryStatus.Completed]: Color.ICGreen,
  [SubscriptionDeliveryStatus.Expired]: Color.ICGray500,
  [SubscriptionDeliveryStatus.InTransit]: Color.ICBlue,
  [SubscriptionDeliveryStatus.Pending]: Color.ICYellow100,
  [SubscriptionDeliveryStatus.Resend]: Color.ICYellow100,
  [SubscriptionDeliveryStatus.Returned]: Color.ICGray500,
  [SubscriptionDeliveryStatus.SendBack]: Color.ICGray500,
  [SubscriptionDeliveryStatus.Shipped]: Color.ICBlue,
  [SubscriptionDeliveryStatus.WaitingForSurcharge]: Color.ICYellow100,
  [SubscriptionDeliveryStatus.WaitingForUpdate]: Color.ICYellow100,
};
