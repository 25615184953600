import { AnimationAction } from '@chic/enums';
import { AnimationActions, AnimationState } from '@chic/models';

export const animationReducer: (state: AnimationState, action: AnimationActions) => AnimationState = (
  state: AnimationState, action: AnimationActions,
): AnimationState => {
  switch (action.type) {
    case AnimationAction.ClearSessionStorage:
      return {};
    case AnimationAction.SaveAnimationStateValues:
      return { ...state, ...action.payload };
    default: 
      return state;
  }
};
