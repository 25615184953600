import { Button, Color, Icon, StyledComponent, ValidationBar } from '@chic-loyalty/ui';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  max-width: 448px;
  padding-bottom: 64px;
  margin: 48px 0 auto;
`;

export const Form: StyledComponent<'form'> = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

export const LoginDescription: StyledComponent<'div'> = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: ${Color.ICWhite100};
  margin-top: 20px;
  text-align: center;
`;

export const StyledLink: StyledComponent<typeof Link> = styled(Link)`
  font-size: 12px;
  text-align: right;
  line-height: 16px;
  text-decoration: none;
  color: ${Color.ICWhite100};
`;

export const ButtonWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background: linear-gradient(189deg, ${Color.ICBlack100} -36.36%, ${Color.ICBlack500} 126.99%);
  padding: 24px 0;
  position: absolute;
  left: 0;
  bottom: 0;
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  margin-top: auto;
`;

export const StyledIcon: StyledComponent<typeof Icon> = styled(Icon)`
  position: absolute;
  top: 15px;
  left: 15px;
`;

export const StyledValidationBar: StyledComponent<typeof ValidationBar> = styled(ValidationBar)`
  margin-top: 20px;
`;
