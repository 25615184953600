import { IconName, TabSwitchOption } from '@chic-loyalty/ui';
import { RefundsStatus } from '@chic/enums';

export const statusFilterTabs: TabSwitchOption[] = [
  {
    name: RefundsStatus.All,
    icon: IconName.ActiveFalse,
    labels: ['chic.management.refundsList.statusFilterTabs.all'],
  },
  {
    name: RefundsStatus.RefundRequest,
    icon: IconName.SmallClock,
    labels: ['chic.management.refundsList.statusFilterTabs.refundRequest'],
  },
  {
    name: RefundsStatus.RefundRequestAccepted,
    icon: IconName.Time,
    labels: ['chic.management.refundsList.statusFilterTabs.refundRequestAccepted'],
  },
  {
    name: RefundsStatus.Refunded,
    icon: IconName.Check,
    labels: ['chic.management.refundsList.statusFilterTabs.refunded'],
  },
];
