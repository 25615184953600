import { 
  ApiResolve, 
  ApiReject,
  ListPagination,
  SetupListElement,
  SetupListParams, 
  SetupCreateDetails, 
  SetupDetails, 
  SetupUpdateData,
  ScreenListParams,
  ScreenListElement,
  ScreenCreateData,
  ScreenDetails,
  ScreenUpdate,
  AssetListParams,
  AssetListElement,
  AssetCreateData,
  AssetDetails,
  AssetUpdateData,
  AdsDictionary,
  AssetUploadData,
  UploadedAssetDetails, 
} from '@chic/models';
import { AxiosResponse } from 'axios';
import { api } from '../api.service';
import { stringify } from 'query-string';

// SETUPS
export const getAdsSetupsList: (params: SetupListParams) => Promise<ListPagination<SetupListElement>> = (
  params: SetupListParams,
): Promise<ListPagination<SetupListElement>> => new Promise(
  (resolve: ApiResolve<ListPagination<SetupListElement>>, reject: ApiReject): Promise<void> => (
    api.get(`/management/ads/setups?${stringify(params, { skipEmptyString: true })}`)
      .then((response: AxiosResponse<ListPagination<SetupListElement>>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const createNewSetup: (data: SetupCreateDetails) => Promise<SetupDetails> = (
  data: SetupCreateDetails,
): Promise<SetupDetails> => new Promise(
  (resolve: ApiResolve<SetupDetails>, reject: ApiReject): Promise<void> => (
    api.post('/management/ads/setups', data)
      .then((response: AxiosResponse<SetupDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const getSetupDetails: (setupId: string) => Promise<SetupDetails> = (
  setupId: string,
): Promise<SetupDetails> => new Promise(
  (resolve: ApiResolve<SetupDetails>, reject: ApiReject): Promise<void> => (
    api.get(`/management/ads/setups/${setupId}`)
      .then((response: AxiosResponse<SetupDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const deleteSetup: (setupId: string) => Promise<void> = (
  setupId: string,
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject): Promise<void> => (
    api.delete(`/management/ads/setups/${setupId}`)
      .then((response: AxiosResponse<void>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const editSetup: (setupId: string, data: SetupUpdateData) => Promise<SetupDetails> = (
  setupId: string, data: SetupUpdateData,
): Promise<SetupDetails> => new Promise(
  (resolve: ApiResolve<SetupDetails>, reject: ApiReject): Promise<void> => (
    api.patch(`/management/ads/setups/${setupId}`, data)
      .then((response: AxiosResponse<SetupDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);

// SCREENS
export const getScreensList: (params: ScreenListParams) => Promise<ListPagination<ScreenListElement>> = (
  params: ScreenListParams,
): Promise<ListPagination<ScreenListElement>> => new Promise(
  (resolve: ApiResolve<ListPagination<ScreenListElement>>, reject: ApiReject): Promise<void> => (
    api.get(`/management/ads/screens?${stringify(params, { skipEmptyString: true })}`)
      .then((response: AxiosResponse<ListPagination<ScreenListElement>>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const createNewScreen: (data: ScreenCreateData) => Promise<ScreenDetails> = (
  data: ScreenCreateData,
): Promise<ScreenDetails> => new Promise(
  (resolve: ApiResolve<ScreenDetails>, reject: ApiReject): Promise<void> => (
    api.post('/management/ads/screens', data)
      .then((response: AxiosResponse<ScreenDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const getScreenDetails: (screenId: string) => Promise<ScreenDetails> = (
  screenId: string,
): Promise<ScreenDetails> => new Promise(
  (resolve: ApiResolve<ScreenDetails>, reject: ApiReject): Promise<void> => (
    api.get(`/management/ads/screens/${screenId}`)
      .then((response: AxiosResponse<ScreenDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const deleteScreen: (screenId: string) => Promise<void> = (
  screenId: string,
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject): Promise<void> => (
    api.delete(`/management/ads/screens/${screenId}`)
      .then((response: AxiosResponse<void>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const editScreen: (screenId: string, data: ScreenUpdate) => Promise<ScreenDetails> = (
  screenId: string, data: ScreenUpdate,
): Promise<ScreenDetails> => new Promise(
  (resolve: ApiResolve<ScreenDetails>, reject: ApiReject): Promise<void> => (
    api.patch(`/management/ads/screens/${screenId}`, data)
      .then((response: AxiosResponse<ScreenDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);

// ASSETS
export const getAssetsList: (params: AssetListParams) => Promise<ListPagination<AssetListElement>> = (
  params: AssetListParams,
): Promise<ListPagination<AssetListElement>> => new Promise(
  (resolve: ApiResolve<ListPagination<AssetListElement>>, reject: ApiReject): Promise<void> => (
    api.get(`/management/ads/assets?${stringify(params, { skipEmptyString: true })}`)
      .then((response: AxiosResponse<ListPagination<AssetListElement>>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const createNewAsset: (data: AssetCreateData) => Promise<AssetDetails> = (
  data: AssetCreateData,
): Promise<AssetDetails> => new Promise(
  (resolve: ApiResolve<AssetDetails>, reject: ApiReject): Promise<void> => (
    api.post('/management/ads/assets', data)
      .then((response: AxiosResponse<AssetDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const getAssetDetails: (assetId: string) => Promise<AssetDetails> = (
  assetId: string,
): Promise<AssetDetails> => new Promise(
  (resolve: ApiResolve<AssetDetails>, reject: ApiReject): Promise<void> => (
    api.get(`/management/ads/assets/${assetId}`)
      .then((response: AxiosResponse<AssetDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const deleteAsset: (assetId: string) => Promise<void> = (
  assetId: string,
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject): Promise<void> => (
    api.delete(`/management/ads/assets/${assetId}`)
      .then((response: AxiosResponse<void>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const editAsset: (assetId: string, data: AssetUpdateData) => Promise<AssetDetails> = (
  assetId: string, data: AssetUpdateData,
): Promise<AssetDetails> => new Promise(
  (resolve: ApiResolve<AssetDetails>, reject: ApiReject): Promise<void> => (
    api.patch(`/management/ads/assets/${assetId}`, data)
      .then((response: AxiosResponse<AssetDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);

// DEVICE
export const refreshDeviceSetups: () => Promise<void> = (): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject): Promise<void> => (
    api.post('/management/ads/devices/refresh')
      .then((response: AxiosResponse<void>): void => resolve(response?.data))
      .catch(reject)
  ),
);

// STATIC
export const getAdsStatic: () => Promise<AdsDictionary> = (): Promise<AdsDictionary> => new Promise(
  (resolve: ApiResolve<AdsDictionary>, reject: ApiReject): Promise<void> => (
    api.get('/management/ads/static')
      .then((response: AxiosResponse<AdsDictionary>): void => resolve(response?.data))
      .catch(reject)
  ),
);

// UPLOAD
export const uploadAsset: (data: AssetUploadData) => Promise<UploadedAssetDetails> = (
  data: AssetUploadData,
): Promise<UploadedAssetDetails> => new Promise(
  (resolve: ApiResolve<UploadedAssetDetails>, reject: ApiReject): Promise<void> => (
    // eslint-disable-next-line @typescript-eslint/naming-convention
    api.post('/management/ads/upload', data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response: AxiosResponse<UploadedAssetDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);
