import { Color, ElementsTableColumn, IconName, StatusSettings, TabSwitchOption } from '@chic-loyalty/ui';
import { InputProps } from '@chic-loyalty/ui/dist/cjs/types/components/input/input.types';
import { DiscountCodeStatus } from '@chic/enums';
import { DiscountCodeCellsNames, DiscountCodesSearchTab } from './discountCodesSearch.enum';

export const discountTabs: TabSwitchOption[] = [
  {
    name: DiscountCodesSearchTab.Code,
    labels: ['chic.management.discountCodeSearchView.discountTabs.code.label'],
    icon: IconName.BigPercent,
  },
  {
    name: DiscountCodesSearchTab.Mail,
    labels: ['chic.management.discountCodeSearchView.discountTabs.mail.label'],
    icon: IconName.BigMail,
  },
  {
    name: DiscountCodesSearchTab.Phone,
    labels: ['chic.management.discountCodeSearchView.discountTabs.phone.label'],
    icon: IconName.SmallForm,
  },
];

export const tableHeader: ElementsTableColumn[] = [
  {
    name: DiscountCodeCellsNames.Code,
    label: 'chic.management.discountCodeSearchView.table.code.label',
    options: { mobileHide: true },
  },
  {
    name: DiscountCodeCellsNames.ExpirationDate,
    label: 'chic.management.discountCodeSearchView.table.date.label',
    options: { isSortable: true },
  },
  {
    name: DiscountCodeCellsNames.PromotionName,
    label: 'chic.management.discountCodeSearchView.table.promotion.label',
    options: { mobileHide: true },
  },
  {
    name: DiscountCodeCellsNames.Status,
    label: 'chic.management.discountCodeSearchView.table.status.label',
    options: { isSortable: true },
  },
  { name: DiscountCodeCellsNames.Link },
];

export const inputProps: Record<DiscountCodesSearchTab, InputProps> = {
  [DiscountCodesSearchTab.Code]: { placeholder: 'chic.management.discountCodeSearchView.inputProps.code.placeholder' },
  [DiscountCodesSearchTab.Mail]: { placeholder: 'chic.management.discountCodeSearchView.inputProps.mail.placeholder' },
  [DiscountCodesSearchTab.Phone]: {
    placeholder: 'chic.management.discountCodeSearchView.inputProps.phone.placeholder',
    hasPhonePrefix: true,
  },
};

export const statusProps: Record<DiscountCodeStatus, StatusSettings> = {
  [DiscountCodeStatus.Active]: { 
    label: 'chic.management.discountCodeStatus.active.label',
    color: Color.ICGreen,
    dotColor: Color.ICGreen,
  },
  [DiscountCodeStatus.Inactive]: {
    label: 'chic.management.discountCodeStatus.inactive.label',
    color: Color.ICGray400,
    dotColor: Color.ICGray400,
  },
  [DiscountCodeStatus.Assigned]: {
    label: 'chic.management.discountCodeStatus.assigned.label',
    color: Color.ICYellow100,
    dotColor: Color.ICYellow100,
  },
  [DiscountCodeStatus.Used]: { 
    label: 'chic.management.discountCodeStatus.used.label',
    color: Color.ICBlue,
    dotColor: Color.ICBlue,
  },
};
