export enum PermissionScopeName {
  // common paths
  AuthUser = 'auth_user',
  AuthUserScopes = 'auth_user_scopes',
  AuthUserUpdateSelfPassword = 'auth_user_update_self_password',
  Scopes = 'scopes',
  AuthUserProfile = 'auth_user_profile',
  ActiveRolesList = 'active_roles_list',
  UserVerificationList = 'user_verification_list',
  UserCrmDetails = 'user_crm_details',
  UserVerificationVerify = 'user_verification_verify',
  ExternalUserCreate = 'external_user_create',
  ExternalUserList = 'external_user_list',
  ExternalUserActivity = 'external_user_activity',
  // custom paths for forks apps
  BackofficePromotionCodesList = 'backoffice_promotion_codes_list',
  BackofficePromotionCodesDetails = 'backoffice_promotion_codes_details',
  BackofficeSubscriptionsSearch = 'backoffice_subscriptions_search',
  BackofficeSubscriptionsDetails = 'backoffice_subscriptions_details',
  BackofficeAdsSetupsList = 'backoffice_ads_setups_list',
  BackofficeAdsSetupsDetails = 'backoffice_ads_setups_details',
  BackofficeAdsSetupsCreate = 'backoffice_ads_setups_create',
  BackofficeAdsSetupsEdit = 'backoffice_ads_setups_edit',
  BackofficeAdsSetupsDelete = 'backoffice_ads_setups_delete',
  BackofficeAdsAssetsList = 'backoffice_ads_assets_list',
  BackofficeAdsAssetsDetails = 'backoffice_ads_assets_details',
  BackofficeAdsAssetsCreate = 'backoffice_ads_assets_create',
  BackofficeAdsAssetsEdit = 'backoffice_ads_assets_edit',
  BackofficeAdsAssetsDelete = 'backoffice_ads_assets_delete',
  BackofficeAdsScreensList = 'backoffice_ads_screens_list',
  BackofficeAdsScreensDetails = 'backoffice_ads_screens_details',
  BackofficeAdsScreensCreate = 'backoffice_ads_screens_create',
  BackofficeAdsScreensEdit = 'backoffice_ads_screens_edit',
  BackofficeAdsScreensDelete = 'backoffice_ads_screens_delete',
  BackofficeAdsFileUpload = 'backoffice_ads_file_upload',
  BackofficeAdsDevicesRefresh = 'backoffice_ads_devices_refresh',
  StaticAdsDictionary = 'static_ads_dictionary',
  BackofficeSubscriptionsRefundsList = 'backoffice_subscriptions_refunds_list',
  BackofficeSubscriptionsRefundsAccept = 'backoffice_subscriptions_refunds_accept',
  BackofficeSubscriptionsOrderCreate = 'backoffice_subscriptions_order_create',
  BackofficeSubscriptionsOrderCancel = 'backoffice_subscriptions_order_cancel',
  BackofficeSubscriptionsPaymentCreate = 'backoffice_subscriptions_payment_create',
  BackofficeSubscriptionsPaymentAccept = 'backoffice_subscriptions_payment_accept',
  BackofficeSubscriptionsDeliveryConfirm = 'backoffice_subscriptions_delivery_confirm',
  BackofficeSubscriptionsDeliveryUpdate = 'backoffice_subscriptions_delivery_update',
  BackofficeSubscriptionsSimulation = 'backoffice_subscriptions_simulation',
  BackofficeSubscriptionsProductUpdate = 'backoffice_subscriptions_product_update',
  BackofficeSubscriptionsProductReplacementList = 'backoffice_subscriptions_product_replacement_list',
  BackofficeSubscriptionsProductReplace = 'backoffice_subscriptions_product_replace',
  BackofficeSubscriptionsCancel = 'backoffice_subscriptions_cancel',
}
