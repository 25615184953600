import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useTitle } from '@chic/hooks';
import {
  Container, 
  ContentBox,
  StyledPageTitle,
} from './discountCodeDetails.styled';

import { PageTitleSize } from '@chic-loyalty/ui';
import { DiscountCodeDetailsComponent } from 'src/components/discountCodeDetails/discountCodeDetails.component';
import { NavigateFunction, useLocation, useNavigate, useParams, Location, Params } from 'react-router-dom';
import { RoutingPath } from '@chic/enums';

export const DiscountCodeDetailsView: React.FC = (): JSX.Element => {
  const { code }: Params = useParams();
  const navigate: NavigateFunction = useNavigate();
  const location: Location = useLocation();
  const hasPreviousState: boolean = location.key !== 'default';
  const { t }: TransProps<never> = useTranslation();
  useTitle(t('chic.management.discountCodeDetailsView.title'));

  const goBackOrHome = (): void => {
    if (hasPreviousState) {
      navigate(-1);
    } else {
      navigate(RoutingPath.DiscountCodesSearch);
    }
  };

  return (
    <Container>
      <StyledPageTitle 
        size={PageTitleSize.Big} 
        label={t('chic.management.discountCodeDetailsView.header')} 
        onClick={goBackOrHome}
      />
      <ContentBox>
        <DiscountCodeDetailsComponent discountCode={code ?? ''} />
      </ContentBox>
    </Container>
  );
};
