import { UseState } from '@chic-loyalty/ui';
import { TransProps, useTranslation } from 'react-i18next';
import { getAdsStatic, getSubscriptionsStatics } from '@chic/api';
import { 
  AdPosType,
  AdsTargetType,
  QueryKey, 
  SubscriptionDeliveryStatus, 
  SubscriptionDeliveryType, 
  SubscriptionOrderStatus, 
  SubscriptionPaymentStatus, 
  SubscriptionPaymentType,
} from '@chic/enums';
import { AdsDictionary, AdsStaticDefinition, StaticDefinition, SubscriptionsStatics, UseStatics } from '@chic/models';
import { useState } from 'react';
import { useQuery } from 'react-query';

export const useStatics: () => UseStatics = (): UseStatics => {
  const { t }: TransProps<never> = useTranslation();
  const [deliveries, setDeliveries]: UseState<StaticDefinition<SubscriptionDeliveryType>[]> 
    = useState<StaticDefinition<SubscriptionDeliveryType>[]>([]);
  const [deliveryStatuses, setDeliveryStatuses]: UseState<StaticDefinition<SubscriptionDeliveryStatus>[]> 
    = useState<StaticDefinition<SubscriptionDeliveryStatus>[]>([]);
  const [paymentStatuses, setPaymentStatuses]: UseState<StaticDefinition<SubscriptionPaymentStatus>[]> 
    = useState<StaticDefinition<SubscriptionPaymentStatus>[]>([]);
  const [orderStatuses, setOrderStatuses]: UseState<StaticDefinition<SubscriptionOrderStatus>[]> 
    = useState<StaticDefinition<SubscriptionOrderStatus>[]>([]);
  const [posList, setPosList]: UseState<AdsStaticDefinition<AdPosType>[]> = useState<AdsStaticDefinition<AdPosType>[]>([]);
  const [targetingList, setTargetingList]: UseState<AdsStaticDefinition<AdsTargetType>[]> 
    = useState<AdsStaticDefinition<AdsTargetType>[]>([]);

  useQuery(
    [QueryKey.SubscriptionsStatics],
    (): Promise<SubscriptionsStatics> => getSubscriptionsStatics(),
    {
      onSuccess: (staticData: SubscriptionsStatics): void => {
        setOrderStatuses(staticData.orderStatuses);
        setDeliveryStatuses(staticData.deliveryStatuses);
        setPaymentStatuses(staticData.paymentStatuses);
        setDeliveries(staticData.deliveryTypes);
      },
      onError: (): void => undefined,
    },
  );

  useQuery(
    [QueryKey.AdsStatics],
    (): Promise<AdsDictionary> => getAdsStatic(),
    {
      onSuccess: (data: AdsDictionary): void => {
        setTargetingList(data.targeting);
        setPosList(data.pos);
      },
      onError: (): void => undefined,
    },
  );

  const getDeliveryNameByType: (type: SubscriptionDeliveryType) => string = (type: SubscriptionDeliveryType): string => {
    return deliveries.find((delivery: StaticDefinition<SubscriptionDeliveryType>): boolean => delivery.code === type)?.name ?? '';
  };

  const getPaymentStatusByType: (type: SubscriptionPaymentStatus) => string = (type: SubscriptionPaymentStatus): string => {
    return paymentStatuses.find((payment: StaticDefinition<SubscriptionPaymentStatus>): boolean => payment.code === type)?.name ?? '';
  };

  const getDeliveryStatusByType: (type: SubscriptionDeliveryStatus) => string = (type: SubscriptionDeliveryStatus): string => {
    return deliveryStatuses.find((delivery: StaticDefinition<SubscriptionDeliveryStatus>): boolean => delivery.code === type)?.name ?? '';
  };

  const getOrderStatusByType: (type: SubscriptionOrderStatus) => string = (type: SubscriptionOrderStatus): string => {
    return orderStatuses.find((order: StaticDefinition<SubscriptionOrderStatus>): boolean => order.code === type)?.name ?? '';
  };

  const getPaymentNameByType: (type: SubscriptionPaymentType) => string = (type: SubscriptionPaymentType): string => {
    switch (type) {
      case SubscriptionPaymentType.CardPayment:
      case SubscriptionPaymentType.Card:
        return t('chic.management.global.cardPayment'); 
      case SubscriptionPaymentType.Payu:
      case SubscriptionPaymentType.PayuPending:
        return t('chic.management.global.payU');
      case SubscriptionPaymentType.Przelewy24:
      case SubscriptionPaymentType.Przelewy24Pending:
        return t('chic.management.global.przelewy24');
      case SubscriptionPaymentType.ManuallyAccepted:
        return t('chic.management.global.manuallyAccepted');
      case SubscriptionPaymentType.PosPayment:
        return t('chic.management.global.posPayment');
      default: 
        return type;
    }
  };

  return { 
    getDeliveryNameByType, 
    getPaymentStatusByType, 
    getPaymentNameByType, 
    getDeliveryStatusByType, 
    getOrderStatusByType,
    orderStatuses,
    posList,
    targetingList,
  };
};
