import React, { useEffect, useMemo, useState } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';
import { Location } from 'history';

import { AppWrapperProps } from './appWrapper.props';
import { AppContainer, Container } from './appWrapper.styled';
import { useAnimationsContext, useAuth, useConfig, useLogger, useRoutingPermissions } from '@chic/hooks';
import { 
  AttachedVerticalMenu, 
  Breakpoint, 
  Footer, 
  MenuPositionData, 
  NavigationMenuItem,
  StickyHeader, 
  UseNotifications, 
  UseState,
  useNotifications, 
} from '@chic-loyalty/ui';
import { AxiosInterceptor } from '../axiosInterceptor/axiosInterceptor.component';
import { 
  ConfigsContextType, 
  FooterPosition, 
  MenuPosition, 
  UseAnimationsContext, 
  UseAuth, 
  UseLogger, 
  UseRoutingPermissions, 
} from '@chic/models';
import { useMediaQuery } from 'react-responsive';
import { getRouteName } from '@chic/utils';
import { RoutingPath } from '@chic/enums';

export const AppWrapper: React.FC<AppWrapperProps> = (props: AppWrapperProps): JSX.Element => {
  const { fullscreenAlertData, children }: AppWrapperProps = props;
  const { isLoggedIn, signOut, authData }: UseAuth = useAuth();
  const { globalErrorHandler }: UseLogger = useLogger();
  const { isMenuPositionAvailable }: UseRoutingPermissions = useRoutingPermissions();
  const { clearSessionStorage }: UseAnimationsContext = useAnimationsContext();
  const location: Location = useLocation();
  const config: ConfigsContextType = useConfig();
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });
  const [isStickyHeaderVisible, setIsStickyHeaderVisible]: UseState<boolean> = useState<boolean>(isTablet);
  const { showFullscreenAlert }: UseNotifications = useNotifications();

  useEffect(
    (): void => {
      if (fullscreenAlertData) {
        showFullscreenAlert(fullscreenAlertData);
      }
    },
    [fullscreenAlertData],
  );

  const hideMenu: () => void = (): void => {
    if (isTablet) {
      setIsStickyHeaderVisible(true);
    }
  };

  const menuPositions: NavigationMenuItem[] = useMemo(
    (): NavigationMenuItem[] => {
      const positions: MenuPosition[] = authData.user?.verified
        ? config.menu.filter(isMenuPositionAvailable)
        : [];
        
      return positions.map((position: MenuPosition): NavigationMenuItem => ({
        internalPath: position.pathname,
        icon: position.inactiveIcon,
        label: position.label,
        onClick: hideMenu,
        name: getRouteName(position.pathname),
      }));
    },
    [authData.user?.verified, config.menu],
  );

  const footerPositions: MenuPositionData[] = useMemo(
    (): MenuPositionData[] => {
      return config.footer.map((position: FooterPosition): MenuPositionData => ({
        label: position.label,
        ...(position.isExternalPath ? { externalUrl: position.path } : { internalPath: position.path }),
      }));
    },
    [config.footer],
  );

  useEffect(
    () : void => window.scrollTo({ top: 0 }),
    [location.pathname],
  );

  useEffect(
    (): void => {
      setIsStickyHeaderVisible(isTablet);
    },
    [isTablet],
  );

  useEffect((): void => globalErrorHandler(), []);

  useEffect(
    (): void => {
      if (!matchRoutes([
        { path: RoutingPath.AdAdd },
        { path: RoutingPath.AdAddScreen },
        { path: RoutingPath.AdEdit },
        { path: RoutingPath.AdEditScreen },
      ], location)?.length) {
        clearSessionStorage();
      }
    },
    [location.pathname],
  );

  return (
    <AxiosInterceptor>
      <Container $withFixedHeight={!isStickyHeaderVisible && isTablet}>
        {isLoggedIn && (
          <AttachedVerticalMenu 
            menuPositions={menuPositions}
            logoActionData={{ internalPath: config.defaultRoute }}
            activeItemName={getRouteName(location.pathname) ?? ''}
            logoutAction={(): void => {
              hideMenu();
              signOut();
            } }
            closeAction={hideMenu}
            isVisible={!isStickyHeaderVisible}
            withReturnTo={false}
          />
        )}
        <AppContainer $hasPadding={isLoggedIn}> 
          {isLoggedIn && (
            <StickyHeader
              isHeaderVisible={isStickyHeaderVisible}
              logoActionData={{ internalPath: config.defaultRoute }} 
              onHamburgerButtonClick={(): void => setIsStickyHeaderVisible(false)}
              hasUserContext={isLoggedIn}
              withReturnTo={false}
            />
          )}
          {children}
          <Footer menuPositions={footerPositions} />
        </AppContainer>
      </Container>
    </AxiosInterceptor>
  );
};
