export enum FileFromViews {
  // global
  Background = '/assets/background.jpg',
  MobileBackground = '/assets/mobileBackground.jpg',
  // subscriptionsList view
  SubscriptionsListViewBackground = '/assets/subscriptionsList/background.png',
  SubscriptionsListViewSearchBackground = '/assets/subscriptionsList/searchBackground.png',
  SubscriptionsListViewSearchIcon = '/assets/subscriptionsList/search.svg',
  // subscriptionDetails view
  SubscriptionDetailsViewImage = '/assets/subscriptionDetails/image.jpg',
  // dicountCodesSearch view
  DiscountCodesSearchMagnifier = '/assets/views/discountCodesSearch/magnifier.svg',
  DiscountCodesSearchMagnifier1 = '/assets/views/discountCodesSearch/magnifier1.svg',
  DiscountCodeSearchClose = '/assets/views/discountCodesSearch/close.svg',
  DiscountCodeSearchDevices = '/assets/views/discountCodesSearch/devices.png',
  // adsList view
  AdsListViewBackground = '/assets/views/adsList/background.png',
  // useAnimations
  UseAnimationsBannerCategories = '/assets/hooks/useAnimations/bannerCategories.png',
  UseAnimationsBannerSlider = '/assets/hooks/useAnimations/bannerSlider.png',
  // baseViewForAnimations
  BaseViewForAnimationsBackground = '/assets/components/baseViewForAnimations/background.png',
  // refundsList view
  RefundsListViewBackground = '/assets/views/refundsList/background.png',
  RefundsListViewBackgroundMobile = '/assets/views/refundsList/backgroundMobile.jpg',
  RefundsListViewValueBoxBackground = '/assets/views/refundsList/valueBoxBackground.jpg',
}
