import { UseNotifications, useNotifications } from '@chic-loyalty/ui';
import {
  cancelSubscription,
  cancelUnpaidSubscriptionOrder,
  manualAcceptPaymentForSubscription,
  manualConfirmSubscriptionDelivery,
} from '@chic/api';
import { TransProps, useTranslation } from 'react-i18next';
import { UseSubscriptionDetailsActions } from '../subscriptionDetails.types';
import { FrontendApiError } from '@chic/models';
import { QueryClient, useQueryClient } from 'react-query';
import { QueryKey } from '@chic/enums';

export const useSubscriptionDetailsActions: () => UseSubscriptionDetailsActions = (): UseSubscriptionDetailsActions => {
  const { showFullscreenAlert, hideFullscreenAlert, addToast }: UseNotifications = useNotifications();
  const { t }: TransProps<never> = useTranslation();
  const queryClient: QueryClient = useQueryClient();

  const cancelOrder: (subscriptionId: number, orderId: number) => void = (subscriptionId: number, orderId: number): void => {
    showFullscreenAlert({
      title: t('chic.management.subscriptionDetailsView.actions.cancelOrder.confirmTitle'),
      acceptButtonSettings: {
        label: t('chic.management.global.yes'),
        action: (): void => {
          cancelUnpaidSubscriptionOrder(subscriptionId, orderId)
            .then((): void => {
              void queryClient.invalidateQueries(QueryKey.SubscriptionDetails); 
              addToast({ content: t('chic.management.subscriptionDetailsView.actions.cancelOrder.successToast') });
            })
            .catch((error: FrontendApiError): void =>
              addToast({ content: t(error.message ?? 'chic.management.subscriptionDetailsView.actions.cancelOrder.error') }),
            );
          hideFullscreenAlert();
        },
      },
      cancelButtonSettings: {
        label: t('chic.management.global.no'),
        action: hideFullscreenAlert,
      },
    });
  };

  const manualConfirmDelivery: (subscriptionId: number, deliveryId: number) => void = (
    subscriptionId: number, deliveryId: number,
  ): void => {
    showFullscreenAlert({
      title: t('chic.management.subscriptionDetailsView.actions.manualConfirmDelivery.confirmTitle'),
      acceptButtonSettings: {
        label: t('chic.management.global.yes'),
        action: (): void => {
          manualConfirmSubscriptionDelivery(subscriptionId, deliveryId)
            .then((): void => {
              void queryClient.invalidateQueries(QueryKey.SubscriptionDetails); 
              addToast({ content: t('chic.management.subscriptionDetailsView.actions.manualConfirmDelivery.successToast') });
            })
            .catch((error: FrontendApiError): void => {
              addToast({
                content: t(
                  error.message ?? 'chic.management.subscriptionDetailsView.actions.manualConfirmDelivery.error',
                ),
              });
            });
          hideFullscreenAlert();
        },
      },
      cancelButtonSettings: {
        label: t('chic.management.global.no'),
        action: hideFullscreenAlert,
      },
    });
  };

  const cancelWholeSubscription: (subscriptionId: number) => void = (subscriptionId: number): void => {
    showFullscreenAlert({
      title: t('chic.management.subscriptionDetailsView.actions.cancelSubscription.confirmTitle'),
      acceptButtonSettings: {
        label: t('chic.management.global.yes'),
        action: (): void => {
          cancelSubscription(subscriptionId)
            .then((): void => {
              void queryClient.invalidateQueries(QueryKey.SubscriptionDetails); 
              addToast({ content: t('chic.management.subscriptionDetailsView.actions.cancelSubscription.successToast') });
            })
            .catch((error: FrontendApiError): void =>
              addToast({ content: t(error.message ?? 'chic.management.subscriptionDetailsView.actions.cancelSubscription.error') }),
            );
          hideFullscreenAlert();
        },
      },
      cancelButtonSettings: {
        label: t('chic.management.global.no'),
        action: hideFullscreenAlert,
      },
    });
  };

  const manualAcceptPayment: (subscriptionId: number, paymentId: number) => void = (subscriptionId: number, paymentId: number): void => {
    showFullscreenAlert({
      title: t('chic.management.subscriptionDetailsView.actions.manualAcceptPayment.confirmTitle'),
      acceptButtonSettings: {
        label: t('chic.management.global.yes'),
        action: (): void => {
          manualAcceptPaymentForSubscription(subscriptionId, paymentId)
            .then((): void => {
              void queryClient.invalidateQueries(QueryKey.SubscriptionDetails); 
              addToast({ content: t('chic.management.subscriptionDetailsView.actions.manualAcceptPayment.successToast') });
            })
            .catch((error: FrontendApiError): void =>
              addToast({ content: t(error.message ?? 'chic.management.subscriptionDetailsView.actions.manualAcceptPayment.error') }),
            );
          hideFullscreenAlert();
        },
      },
      cancelButtonSettings: {
        label: t('chic.management.global.no'),
        action: hideFullscreenAlert,
      },
    });
  };

  return {
    cancelOrder,
    manualConfirmDelivery,
    cancelWholeSubscription,
    manualAcceptPayment,
  };
};
