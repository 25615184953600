import { Color, StatusSettings } from '@chic-loyalty/ui';
import { DiscountCodeStatus } from '@chic/enums';

export const codeStatuses: Record<DiscountCodeStatus, StatusSettings> = {
  [DiscountCodeStatus.Active]: { 
    label: 'chic.management.discountCodeStatus.active.label',
    color: Color.ICGreen,
  },
  [DiscountCodeStatus.Inactive]: {
    label: 'chic.management.discountCodeStatus.inactive.label',
    color: Color.ICGray400,
  },
  [DiscountCodeStatus.Assigned]: {
    label: 'chic.management.discountCodeStatus.assigned.label',
    color: Color.ICYellow100,
  },
  [DiscountCodeStatus.Used]: { 
    label: 'chic.management.discountCodeStatus.used.label',
    color: Color.ICBlue,
  },
};
