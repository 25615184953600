import { Breakpoint, CalcSize, Color, EmptyListCover, Grid, Pagination, StyledComponent, TabSwitch } from '@chic-loyalty/ui';
import { FileFromViews } from '@chic/enums';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  min-height: calc(100vh - ${CalcSize.FooterHeight});
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('${FileFromViews.RefundsListViewBackground}');
  background-color: ${Color.ICBlack500};
  background-repeat: no-repeat;
  background-size: 100% 306px;

  @media ${Breakpoint.Mobile} {
    background: url('${FileFromViews.RefundsListViewBackgroundMobile}');
    background-size: contain;
    background-color: ${Color.ICBlack400};
    background-repeat: no-repeat;
  }
`;

export const PageTitleWrapper: StyledComponent<'div'> = styled.div`
  max-width: calc(${Grid.ICSmallDesktop} + 48px);
  width: 100%;
  padding: 24px 24px 0;
`;

export const InnerContainer: StyledComponent<'div'> = styled.div`
  max-width: calc(${Grid.ICSmallDesktop} + 48px);
  width: 100%;
  height: 100%;
  padding: 24px 24px 0;
  flex: 1;
`;

export const StyledPagination: StyledComponent<typeof Pagination> = styled(Pagination)`
  margin: 48px 0;
`;

export const AccordionChildrenContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const AccordionChildrenInnerContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  gap: 24px;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
  }
`;

export const ValueBoxContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  max-width: 474px;
  width: 100%;

  @media ${Breakpoint.Mobile} {
    max-width: 100%;
    height: 144px;
  }
`;

export const DetailsEditBoxes: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
  min-width: 400px;

  @media ${Breakpoint.Mobile} {
    min-width: 0;
  }
`;

export const ButtonsContainer: StyledComponent<'div'> = styled.div`
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(188.65deg, ${Color.ICBlack100} -36.36%, ${Color.ICBlack500} 126.99%);
  width: 100%;
  padding: 40px 24px;
`;

export const StyledEmptyListCover: StyledComponent<typeof EmptyListCover> = styled(EmptyListCover)`
  margin-top: 48px;
`;

export const StyledTabSwitch: StyledComponent<typeof TabSwitch> = styled(TabSwitch)`
  margin-bottom: 60px;

  @media ${Breakpoint.Mobile} {
    margin-bottom: 48px;
  }
`;

export const ScrollToTopButton: StyledComponent<'button'> = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  color: ${Color.ICYellow100};
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.4px;
  text-align: center;
  margin-bottom: 48px;
`;
