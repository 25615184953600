import React, { useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { 
  ButtonsWrapper,
  Container, 
  InnerContainer, 
  PageTitleButtons, 
  PageTitleWrapper, 
  Spacer, 
  StyledPagination, 
  StyledTabSwitch, 
} from './adsList.styled';
import { 
  Breakpoint, 
  Button, 
  ButtonTheme, 
  ComponentColorTheme, 
  EditItemRow, 
  EditItemsRows, 
  EditItemsRowsTheme, 
  EmptyListCover, 
  IconName, 
  PageTitle, 
  PageTitleSize, 
  PaginationDetails, 
  useNotifications, 
  UseNotifications, 
  usePagination, 
  useRedirect, 
  UseRedirect, 
  UseState, 
} from '@chic-loyalty/ui';
import { useMediaQuery } from 'react-responsive';
import { useTitle } from '@chic/hooks';
import { useQuery } from 'react-query';
import { QueryKey, RoutingPath, SetupType, SortOrder } from '@chic/enums';
import { FrontendApiError, ListPagination, SetupListElement } from '@chic/models';
import { getAdsSetupsList, refreshDeviceSetups } from '@chic/api';

export const AdsListView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { setMaxItems, pagesCount, page, setPage, itemsPerPage, offset }: PaginationDetails = usePagination();
  const { redirect }: UseRedirect = useRedirect();
  const { addToast }: UseNotifications = useNotifications();
  const [activeTab, setActiveTab]: UseState<SetupType> = useState<SetupType>(SetupType.Tablet);
  const [adsList, setAdsList]: UseState<EditItemRow[] | null> = useState<EditItemRow[] | null>(null);
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  useTitle(t('chic.management.adsListView.title'));

  useEffect((): void => setPage(1), [activeTab]);

  useQuery(
    [QueryKey.AdsList, activeTab, offset, page],
    (): Promise<ListPagination<SetupListElement>> => getAdsSetupsList({
      type: activeTab,
      limit: itemsPerPage,
      order: SortOrder.Descending,
      offset,
    }),
    {
      onSuccess: (data: ListPagination<SetupListElement>): void => {
        setMaxItems(data.amount);
        setAdsList(data.list.map((setup: SetupListElement): EditItemRow => ({
          icon: IconName.PhoneOutline,
          details: {
            label: t('chic.management.adsListView.adsList.configurationName'),
            value: setup.name,
          },
          buttons: [
            {
              label: t('chic.management.adsListView.adsList.edit'),
              action: (): void => redirect(RoutingPath.AdEdit, { type: setup.type, id: setup.id }),
              buttonTheme: ButtonTheme.ICText,
            },
          ],
        })));
      },
      onError: (error: FrontendApiError): void => {
        setAdsList([]);
        addToast({ content: error.message ?? '' });
      },
    },
  );

  const onRefreshAnimations: () => void = (): void => {
    refreshDeviceSetups()
      .then((): void => {
        addToast({ content: t('chic.management.adsListView.onRefreshAnimations.success'), icon: IconName.Check });
      })
      .catch((error: FrontendApiError): void => {
        addToast({ content: t('chic.management.adsListView.onRefreshAnimations.error', { error: error.message }) });
      });
  };

  return (
    <Container>
      <PageTitleWrapper>
        <PageTitle label={t('chic.management.adsListView.pageTitle')} size={PageTitleSize.Big} />
        {!isMobile && (
          <PageTitleButtons>
            <Button 
              buttonTheme={ButtonTheme.ICText} 
              label={t('chic.management.adsListView.button.refreshAnimations')} 
              onClick={onRefreshAnimations}
            />
            <Spacer />
            <Button 
              buttonTheme={ButtonTheme.ICText} 
              label={t('chic.management.adsListView.button.addNewAnimation')} 
              onClick={(): void => redirect(RoutingPath.AdAdd, { type: activeTab })}
            />
          </PageTitleButtons>
        )}
      </PageTitleWrapper>
      <InnerContainer>
        <StyledTabSwitch 
          tabs={[
            {
              name: SetupType.Tablet,
              labels: [t('chic.management.adsListView.tabs.tablet')],
            },
            {
              name: SetupType.Tv,
              labels: [t('chic.management.adsListView.tabs.tv')],
            },
          ]} 
          onTabChange={(tabName: string): void => setActiveTab(tabName as SetupType)}
          initialTabName={activeTab}
        />
        {adsList !== null && (
          adsList.length ? (
            <EditItemsRows 
              rows={adsList} 
              theme={EditItemsRowsTheme.Edit}  
            />
          ) : (
            <EmptyListCover 
              header={t('chic.management.adsListView.emptyList.header')} 
              subheader={t('chic.management.adsListView.emptyList.subheader')} 
            />
          )
        )}
        {pagesCount > 1 && (
          <StyledPagination 
            activePage={page} 
            pagesCount={pagesCount} 
            onActivePageChange={setPage} 
            colorTheme={ComponentColorTheme.IC} 
          />
        )}
      </InnerContainer>
      {isMobile && (
        <ButtonsWrapper>
          <Button 
            buttonTheme={ButtonTheme.ICPrimary} 
            label={t('chic.management.adsListView.button.refreshAnimations')} 
            onClick={onRefreshAnimations}
            fullWidth
          />
          <Button 
            buttonTheme={ButtonTheme.ICSecondary} 
            label={t('chic.management.adsListView.button.addNewAnimation')} 
            onClick={(): void => redirect(RoutingPath.AdAdd, { type: activeTab })}
            fullWidth
          />
        </ButtonsWrapper>
      )}
    </Container>
  );
};
