import { Color } from '@chic-loyalty/ui';
import { SubscriptionOrderStatus } from '@chic/enums';

export const orderStatusColor: Record<SubscriptionOrderStatus, Color> = {
  [SubscriptionOrderStatus.Complete]: Color.ICGreen,
  [SubscriptionOrderStatus.Failed]: Color.ICRed,
  [SubscriptionOrderStatus.OnDelivery]: Color.ICBlue,
  [SubscriptionOrderStatus.Pending]: Color.ICYellow100,
  [SubscriptionOrderStatus.WaitingForPayment]: Color.ICYellow100,
  [SubscriptionOrderStatus.RefundRequest]: Color.ICYellow100,
  [SubscriptionOrderStatus.RefundRequestAccepted]: Color.ICBlue,
  [SubscriptionOrderStatus.Refunded]: Color.ICGreen,
};
