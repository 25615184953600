import { UseState } from '@chic-loyalty/ui';
import { getSubscriptionDetails } from '@chic/api';
import { SubscriptionDetails, SubscriptionOrder, UseAuth } from '@chic/models';
import { useQuery } from 'react-query';
import { PermissionScopeName, QueryKey, SubscriptionPaymentStatus, SubscriptionPermissionId, SubscriptionStatus } from '@chic/enums';
import { useMemo, useState } from 'react';
import { useAuth } from '@chic/hooks';
import { emptyRequest } from '@chic/utils';
import { Permission } from '@chic/interfaces';
import { ActionTypes, UseSubscriptionDetailsActionsPermissions } from '../subscriptionDetails.types';

export const useSubscriptionDetailsActionsPermissions: (subscriptionId: number | null) => UseSubscriptionDetailsActionsPermissions = (
  subscriptionId: number | null,
): UseSubscriptionDetailsActionsPermissions => {
  const { authData: { user } }: UseAuth = useAuth();
  const [subscriptionDetails, setSubscriptionDetails]: UseState<SubscriptionDetails | null> = useState<SubscriptionDetails | null>(null);

  useQuery(
    [QueryKey.SubscriptionDetails],
    (): Promise<SubscriptionDetails | void> => subscriptionId
      ? getSubscriptionDetails(subscriptionId)
      : emptyRequest(),
    {
      enabled: !!subscriptionId,
      onSuccess: (data: SubscriptionDetails | void): void => {
        if (!data) {
          return;
        }

        setSubscriptionDetails(data);
      },
    },
  );
  
  const isActionPermitted: (
    scope: PermissionScopeName, permissionId?: SubscriptionPermissionId
  ) => boolean = (
    scope: PermissionScopeName, permissionId?: SubscriptionPermissionId,
  ): boolean => {
    if (subscriptionDetails?.contract.status !== SubscriptionStatus.Active) {
      return false;
    }

    let subscriptionPermission: Permission<SubscriptionPermissionId> | undefined;
    if (permissionId) {
      subscriptionPermission = subscriptionDetails?.permissions
        .find((item: Permission<SubscriptionPermissionId>): boolean => item.id === permissionId);
    }

    return !!user?.scopes.includes(scope) && (!subscriptionPermission || !!subscriptionPermission.value);
  };

  const actionsPermissions: Record<ActionTypes, boolean> = useMemo(
    (): Record<ActionTypes, boolean> => {
      const currentOrder: SubscriptionOrder | undefined = subscriptionDetails?.orders[0];

      return {
        'cancelOrder': isActionPermitted(PermissionScopeName.BackofficeSubscriptionsOrderCancel, SubscriptionPermissionId.CanCancelOrder),
        'manualConfirmDelivery': (
          isActionPermitted(PermissionScopeName.BackofficeSubscriptionsDeliveryConfirm)
          // TODO: move to SubscriptionPermissionId (backend)
          && !!currentOrder?.deliveries[currentOrder?.deliveries?.length - 1]
        ),
        'cancelWholeSubscription': isActionPermitted(PermissionScopeName.BackofficeSubscriptionsCancel),
        'manualAcceptPayment': (
          isActionPermitted(PermissionScopeName.BackofficeSubscriptionsPaymentAccept)
          // TODO: move to SubscriptionPermissionId (backend)
          && currentOrder?.payments[currentOrder?.payments?.length - 1]?.status === SubscriptionPaymentStatus.Pending
        ),
      };
    },
    [user?.scopes, subscriptionDetails],
  );

  return { actionsPermissions };
};
