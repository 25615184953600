import {
  UseState, 
  DetailsTableRow, 
  DetailsRowTheme, 
  DetailsTableCellTheme, 
  DetailsTableCellType, 
  VisibilitySettings,
  DetailRow,
  Color,
  UseParsers,
  useParsers,
  ProgressBarTab,
  UseNotifications,
  useNotifications,
  UseRedirect,
  useRedirect, 
} from '@chic-loyalty/ui';
import { TransProps, useTranslation } from 'react-i18next';
import { getSubscriptionDetails } from '@chic/api';
import { QueryKey, RoutingPath, SubscriptionOrderStatus, SubscriptionPickupPointType } from '@chic/enums';
import { 
  SubscriptionOrder, 
  SubscriptionContractAmendment, 
  SubscriptionDeliveryAddress, 
  SubscriptionDetails, 
  UseStatics,
  StaticDefinition,
  FrontendApiError, 
} from '@chic/models';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { UseSubscriptionDetailsData } from '../subscriptionDetails.types';
import { AmendmentsTableColumnName } from '../subscriptionDetails.enum';
import { useStatics } from '@chic/hooks';
import { emptyRequest } from '@chic/utils';

export const useSubscriptionDetailsData: (subscriptionId: number | null) => UseSubscriptionDetailsData = (
  subscriptionId: number | null,
): UseSubscriptionDetailsData => {
  const { t }: TransProps<never> = useTranslation();
  const { parsePrice }: UseParsers = useParsers();
  const { showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const { redirect }: UseRedirect = useRedirect();
  const { 
    getDeliveryNameByType, 
    getPaymentNameByType,
    orderStatuses,
  }: UseStatics = useStatics();
  const [amendmentsTable, setAmendmentsTable]: UseState<DetailsTableRow[]> = useState<DetailsTableRow[]>([]);
  const [subscriptionDetails, setSubscriptionDetails]: UseState<SubscriptionDetails | null> = useState<SubscriptionDetails | null>(null);
  const [currentOrder, setCurrentOrder]: UseState<SubscriptionOrder | undefined> = useState<SubscriptionOrder | undefined>(undefined);
  const [subscriptionOrders, setSubscriptionOrders]: UseState<SubscriptionOrder[]> = useState<SubscriptionOrder[]>([]);
  const currency: string = 'zł';
  
  useQuery(
    [QueryKey.SubscriptionDetails],
    (): Promise<SubscriptionDetails | void> => subscriptionId
      ? getSubscriptionDetails(subscriptionId)
      : emptyRequest(),
    {
      enabled: !!subscriptionId,
      onSuccess: (data: SubscriptionDetails | void): void => {
        if (!data) {
          return;
        }

        setSubscriptionDetails(data);
        if (data.orders[0]?.status !== SubscriptionOrderStatus.Complete) {
          setCurrentOrder(data.orders[0]);
          setSubscriptionOrders(data.orders.slice(1));
        } else {
          setCurrentOrder(undefined);
          setSubscriptionOrders(data.orders);
        }

        if (data.amendments.length) {
          setAmendmentsTable(
            data.amendments.map((amendment: SubscriptionContractAmendment): DetailsTableRow => ({
              theme: DetailsRowTheme.Normal,
              cells: [
                {
                  name: AmendmentsTableColumnName.Id,
                  value: String(amendment.id),
                  cellTheme: DetailsTableCellTheme.ICWhite,
                  cellType: DetailsTableCellType.Text,
                  visibilitySettings: VisibilitySettings.Always,
                },
                {
                  name: AmendmentsTableColumnName.Type,
                  value: amendment.label,
                  cellTheme: DetailsTableCellTheme.ICWhite,
                  cellType: DetailsTableCellType.Text,
                  visibilitySettings: VisibilitySettings.Always,
                },
                {
                  name: AmendmentsTableColumnName.Status,
                  value: t(`chic.management.amendmentsTable.status.${amendment.status}`),
                  cellTheme: DetailsTableCellTheme.ICWhite,
                  cellType: DetailsTableCellType.Text,
                  visibilitySettings: VisibilitySettings.Always,
                },
                {
                  name: AmendmentsTableColumnName.CreateDatetime,
                  value: amendment.createDatetime,
                  cellTheme: DetailsTableCellTheme.ICWhite,
                  cellType: DetailsTableCellType.Text,
                  visibilitySettings: VisibilitySettings.Always,
                },
                {
                  name: AmendmentsTableColumnName.UpdateDatetime,
                  value: amendment.updateDatetime ?? t('chic.management.global.noData'),
                  cellTheme: DetailsTableCellTheme.ICWhite,
                  cellType: DetailsTableCellType.Text,
                  visibilitySettings: VisibilitySettings.Always,
                },
              ],
            })),
          );
        }
      },
      onError: (error: FrontendApiError): void => {
        showFullscreenAlert({
          title: t('chic.crmApp.global.error'),
          description: error.message ?? t('chic.crmApp.global.errorMessage'),
          acceptButtonSettings: {
            label: t('chic.management.useSubscriptionDetails.goBackToList'),
            action: (): void => {
              redirect(RoutingPath.SubscriptionList);
              hideFullscreenAlert();
            },
          },
        });
      },
    },
  );

  const transformLocationToDeliveryAddress: (location: SubscriptionDeliveryAddress) => string = (
    location: SubscriptionDeliveryAddress,
  ): string => {
    if (location.pickupPoint) {
      switch (location.pickupPoint.type) {
        case SubscriptionPickupPointType.InpostMachine:
          return t(
            'chic.management.useSubscriptionDetails.transformLocationToDeliveryAddress.inpostMachine', 
            { id: location.pickupPoint.externalId },
          );
        case SubscriptionPickupPointType.DpdPickupPoint:
          return t(
            'chic.management.useSubscriptionDetails.transformLocationToDeliveryAddress.dpdPickupPoint', 
            { id: location.pickupPoint.externalId },
          );
        case SubscriptionPickupPointType.PosSelfPickup:
          return t(
            'chic.management.useSubscriptionDetails.transformLocationToDeliveryAddress.posSelfPickup', 
            { id: location.pickupPoint.externalId },
          );
        default: 
          return '';
      }
    } else {
      return `${location.name},
      ${location.address},
      ${location.postalCode} ${location.city}`;
    }   
  };

  const detailsRowsData: DetailRow[] = useMemo(
    (): DetailRow[] => {
      if (!subscriptionDetails) {
        return [];
      }

      return [
        {
          label: t('chic.management.useSubscriptionDetails.detailsRowsData.planName'),
          value: `**${subscriptionDetails.contract.planName}**`,
          color: Color.ICBlue,
        },
        {
          label: t('chic.management.useSubscriptionDetails.detailsRowsData.regularPrice'),
          value: `${parsePrice(subscriptionDetails.contract.regularPrice)} ${currency}`,
        },
        {
          label: t('chic.management.useSubscriptionDetails.detailsRowsData.discountPercentage'),
          value: `${subscriptionDetails.contract.discountPercentage}%`,
        },
        {
          label: t('chic.management.useSubscriptionDetails.detailsRowsData.deliveryCost'),
          value: `${parsePrice(subscriptionDetails.contract.deliveryCost)} ${currency}`,
        },
        {
          label: t('chic.management.useSubscriptionDetails.detailsRowsData.discountedPrice'),
          value: `${parsePrice(subscriptionDetails.contract.discountedPrice + subscriptionDetails.contract.deliveryCost)} ${currency}`,
        },
        {
          label: t('chic.management.useSubscriptionDetails.detailsRowsData.initDatetime'),
          value: subscriptionDetails.contract.initDatetime,
        },
        {
          label: t('chic.management.useSubscriptionDetails.detailsRowsData.storeName'),
          value: subscriptionDetails.contract.initLocation?.store 
            ? `${subscriptionDetails.contract.initLocation.store.name} ${subscriptionDetails.contract.initLocation.store.address}`
            : t('chic.management.global.noData'),
        },
        {
          label: t('chic.management.useSubscriptionDetails.detailsRowsData.employeeName'),
          value: subscriptionDetails.contract.initEmployee.employeeName ?? t('chic.management.global.noData'),
        },
        {
          label: t('chic.management.useSubscriptionDetails.detailsRowsData.deliveryAddress'),
          value: transformLocationToDeliveryAddress(subscriptionDetails.contract.delivery),
        },
        {
          label: t('chic.management.useSubscriptionDetails.detailsRowsData.deliveryType'),
          value: subscriptionDetails.contract.delivery.type
            ? getDeliveryNameByType(subscriptionDetails.contract.delivery.type)
            : t('chic.management.global.noData'),
        },
        {
          label: t('chic.management.useSubscriptionDetails.detailsRowsData.paymentType'),
          value: getPaymentNameByType(subscriptionDetails.contract.paymentType),
        },
        {
          label: t('chic.management.useSubscriptionDetails.detailsRowsData.paymentUrl'),
          value: currentOrder?.payments[currentOrder.payments.length - 1]?.url ?? (currentOrder?.status &&
            [SubscriptionOrderStatus.OnDelivery, SubscriptionOrderStatus.Complete].includes(currentOrder.status) 
            ? t('chic.management.useSubscriptionDetails.detailsRowsData.orderPaid')
            : t('chic.management.global.noData')
          ),
          color: Color.ICYellow100,
          isCopyable: !!currentOrder?.payments[currentOrder.payments.length - 1]?.url,
        },
      ];
    },
    [subscriptionDetails, getDeliveryNameByType],
  );

  const orderProgressBarStatuses: ProgressBarTab[] = useMemo(
    (): ProgressBarTab[] => {
      if (!currentOrder) {
        return [];
      }

      return orderStatuses
        // TODO: fix when be will be ready
        .filter((status: StaticDefinition<SubscriptionOrderStatus>): boolean => [
          SubscriptionOrderStatus.Complete, 
          SubscriptionOrderStatus.OnDelivery, 
          SubscriptionOrderStatus.WaitingForPayment,
        ].includes(status.code))
        .map((status: StaticDefinition<SubscriptionOrderStatus>): ProgressBarTab => ({
          label: status.name,
          name: status.code,
        }));

    },
    [currentOrder, orderStatuses],
  );

  return { 
    transformLocationToDeliveryAddress,
    amendmentsTable,
    subscriptionDetails,
    subscriptionOrders,
    currentOrder,
    detailsRowsData,
    orderProgressBarStatuses,
  };
};
