import { 
  Breakpoint, 
  CalcSize, 
  Color, 
  CustomerContactBox, 
  DetailsInfoGroup, 
  FontWeight, 
  Grid, 
  PageTitle, 
  StyledComponent, 
  TransactionProductBox, 
} from '@chic-loyalty/ui';

import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  min-height: calc(100vh - ${CalcSize.FooterHeight});
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media ${Breakpoint.Mobile} {
    background-color: ${Color.ICBlack400};
    border-top: 1px solid ${Color.ICGray500};
  }
`;

export const PageTitleWrapper: StyledComponent<'div'> = styled.div`
  max-width: calc(${Grid.ICSmallDesktop} + 48px);
  width: 100%;
  padding: 24px 24px 0;
  
  @media ${Breakpoint.Mobile} {
    padding: 30px 24px;
  }
`;

export const Section: StyledComponent<'div'> = styled.div`
  max-width: calc(${Grid.ICSmallDesktop} + 48px);
  width: 100%;
  padding: 24px 24px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 62px;
`;

export const HistorySection: StyledComponent<'div'> = styled.div`
  max-width: calc(${Grid.ICSmallDesktop} + 48px);
  width: 100%;
  padding: 24px 24px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 62px;

  @media ${Breakpoint.Mobile} {
    padding: 24px 0 0;
    gap: 0;
  }
`;

export const HistoryPageTitle: StyledComponent<typeof PageTitle> = styled(PageTitle)`
  @media ${Breakpoint.Mobile} {
    padding: 0 24px;
    margin-bottom: 20px;
  }
`;

export const HistoryChildrenContainer: StyledComponent<'div'> = styled.div`
  @media ${Breakpoint.Mobile} {
    padding: 0 24px;
  }
`;

export const GrayContainer: StyledComponent<'div'> = styled.div`
  padding: 20px;
  background-color: ${Color.ICBlack400};

  @media ${Breakpoint.Mobile} {
    background-color: ${Color.ICBlack300};
  }
`;

export const CustomerContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
`;

export const StyledCustomerContactBox: StyledComponent<typeof CustomerContactBox> = styled(CustomerContactBox)`
  width: 50%;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const ImageBox: StyledComponent<'div'> = styled.div`
  width: 50%;
  height: 190px;

  @media ${Breakpoint.Mobile} {
    width: 100%;
    height: 138px;
  }
`;

export const Image: StyledComponent<'img'> = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  clip-path: polygon(-12px 0, 100% 0, 100% calc(100% - 16px), calc(100% - 16px) 100%, -12px 100%);

  @media ${Breakpoint.Mobile} {
    clip-path: unset;
  }
`;

export const DetailsWrapper: StyledComponent<'div'> = styled.div`
  width: 100%;
`;

export const StyledTransactionProductBox: StyledComponent<typeof TransactionProductBox> = styled(TransactionProductBox)`
  padding: 24px 0;

  & + & {
    border-top: 1px solid ${Color.ICGray500};
  }
`;

export const CurrentOrderContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const DetailsEditBoxes: StyledComponent<'div'> = styled.div`
  display: flex;
  gap: 20px;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
  }
`;

export const ProductsBox: StyledComponent<'div'> = styled.div`
  margin: 40px 0 0;
`;

export const HistoryTableWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  gap: 24px;
  margin: 24px 0;
`;

export const SmallImageBox: StyledComponent<'div'> = styled.div`
  max-width: 387px;
  width: 100%;
  height: auto;
`;

export const StyledDetailsInfoGroup: StyledComponent<typeof DetailsInfoGroup> = styled(DetailsInfoGroup)`
  margin-bottom: 40px;
`;

export const OrderIdBox: StyledComponent<'div'> = styled.div`
  padding: 8px 12px;
  margin-left: 20px;
  border: 1px solid ${Color.ICBlue};
  color: ${Color.ICWhite100};
  font-size: 12px;
  font-weight: ${FontWeight.SemiBold};
  line-height: 16px;
`;

export const SmallActionBoxesWrapper: StyledComponent<'div'> = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 20px;

  @media ${Breakpoint.CustomDesktop} {
    flex-direction: column;
  }
`;

export const CurrentOrderButtons: StyledComponent<'div'> = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 20px;

  @media ${Breakpoint.CustomDesktop} {
    flex-direction: column;
  }
`;

export const DeactivateContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(188.65deg, ${Color.ICBlack100} -36.36%, ${Color.ICBlack500} 126.99%);
  margin-top: 96px;
`;
