export enum AmendmentsTableColumnName {
  Id = 'id',
  Type = 'type',
  Status = 'status',
  CreateDatetime = 'createDatetime',
  UpdateDatetime = 'updateDatetime',
}

export enum HistoryPaymentsTableColumnName {
  Id = 'id',
  Type = 'type',
  Status = 'status',
  Price = 'price',
}

export enum HistoryDeliveriesTableColumnName {
  Type = 'type',
  Status = 'status',
  Price = 'price',
  Address = 'address',
}
