import { SubscriptionsListActions } from './subscriptionsList.enum';
import { SubscriptionsListAction, SubscriptionsListState } from './subscriptionsList.types';

export const subscriptionsListReducer: (
  state: SubscriptionsListState, action: SubscriptionsListAction,
) => SubscriptionsListState = (
  state: SubscriptionsListState, action: SubscriptionsListAction,
): SubscriptionsListState => {
  switch (action.type) {
    case SubscriptionsListActions.SetSortKey:
      return { ...state, sortKey: action.payload };
    case SubscriptionsListActions.SetSortOrder:
      return { ...state, sortOrder: action.payload };
    case SubscriptionsListActions.SetFilterValue:
      return { ...state, filterValue: action.payload };
  }
};
