export enum SubscriptionDeliveryStatus {
  Pending = 'pending',
  Collected = 'collected',
  InTransit = 'in_transit',
  Shipped = 'shipped',
  Returned = 'returned',
  Completed = 'completed',
  WaitingForUpdate = 'waiting_for_update',
  WaitingForSurcharge = 'waiting_for_surcharge',
  Resend = 'resend',
  Expired = 'expired',
  SendBack = 'send_back',
}
