export enum SubscriptionOrderStatus {
  Pending = 'pending',
  WaitingForPayment = 'waiting_for_payment',
  OnDelivery = 'on_delivery',
  Failed = 'failed',
  RefundRequest = 'refund_request',
  RefundRequestAccepted = 'refund_request_accepted',
  Refunded = 'refunded',
  Complete = 'complete',
}
